/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Dzi_Data_EntriesList.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "патанатомия". В этом файле находится компонент, отвечающий за вывод списка кейсов конкретной категории.
 *	@author: Манжос Геннадий Юрьевич
*/
.dzi_items_flex {
    margin: 0px auto;
    width: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 1px;
    grid-row-gap: 1px;
    margin-bottom: 50px;
}

@media (max-width: 600px) {
    .dzi_items_flex {
        grid-template-columns: 1fr;
    }

}