/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Footer.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Footer.css
 *	@author: Манжос Геннадий Юрьевич,     Горбас Александр Петрович
*/
.footer {
    width: 100%;
    height: 100%;
    bottom: -500px;
    border-top: 1px solid rgba(202, 202, 202, 0.223);
    background-color: #f7f7f7;
    font-size: 16px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
}

.footer_top_section {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    gap: 40px;
    padding-top: 20px;
}

.icon_footer {
    user-select: none;
    display: flex;
    max-width: 500px;
}

.footer_top_section a:first-child .icon_footer {
    max-width: 12rem;
    height: auto;
    margin-bottom: 10px;
}

.footer_grid {
    line-height: 20px;
    margin: 0 auto;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    justify-content: center;
}

.menu_block_footer,
.documentation_block_footer,
.contacts_block_footer,
.subscribe_block_footer {
    text-align: center;
    user-select: none;
}

.menu_block_footer {
    order: 1;
}

.footer_title {
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 22px;
    padding: 0px 0 20px 0;
    color: rgb(150, 44, 44);
}

.documentation_block_footer {
    order: 2;
}

.contacts_block_footer {
    order: 3;
}

.subscribe_block_footer {
    order: 4;
}

.list-unstyled{
    text-align: center;
    user-select: none;
    line-height: 5px;
    padding-top: none;
}

.list-unstyled li {
    margin-bottom: 20px;
}

.footer_grid ul,
.footer_grid ol {
    margin: 20px;
    padding: 0;
    list-style-type: none;
}

.footer_text {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 1.2;
    text-decoration: none;
    color: rgb(64, 64, 64);
    display: block;
}

.footer_text:hover {
    text-decoration: none;
    color: rgb(150, 44, 44);
}

.hr_copyright {
    margin: 0px auto;
    display: flex;
    justify-content: center;
    width: 485px;
}

.copyright {
    user-select: none;
    padding: 20px;
    margin: 10px 0px 10px 0px;
    font-size: 15px;
    color: rgb(100, 100, 100);
}

/* .footer_medatlas_title {
    user-select: none;
    cursor: default;
    margin-bottom: 50px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: rgb(168, 28, 28);
    display: flex;
    justify-content: center;
} */

@media (max-width: 1023px) {
    /* .footer {
        padding: 0px 50px 0px 0px;
    } */
    .footer_top_section {
        gap: 20px;
    }
    .icon_footer {
        max-width: 350px;
        display: flex;
    }
    .footer_top_section a:first-child .icon_footer {
        max-width: 10rem;
        margin-bottom: 7px;
    }
    .footer_grid {
        width: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        text-align: center;
    }
    .menu_block_footer {
        grid-area: 1 / 1 / 2 / 2;
    }
    .footer_title {
        font-size: 22px;
    }
    .documentation_block_footer {
        grid-area: 1 / 2 / 2 / 3;
    }
    .contacts_block_footer {
        grid-area: 2 / 1 / 3 / 2;
    }
    .footer_text {
        font-size: 17px;
        line-height: 1.2;
    }
    .list-unstyled li {
        margin-bottom: 18px;
    }
    .hr_copyright {
        margin: 0px auto;
        display: flex;
        justify-content: center;
        width: 280px;
    }
    .copyright {
        text-align: center;
        width: 290px;
        padding: 20px;
        font-size: 14px;
    }
}

@media (max-width: 567px) {
    .footer {
        padding: 0px 0px 0px 0px;
    }
    .icon_footer {
        max-width: 250px;
    }
    .footer_top_section a:first-child .icon_footer {
        max-width: 7rem;
        margin-bottom: 6px;
    }
    .footer_title {
        font-size: 18px;
        padding: 0px;
    }
}
@media (max-width: 426px) {
    .footer_top_section {
        gap: 6px;
    }
    .icon_footer {
        max-width: 200px;
    }
    .footer_top_section a:first-child .icon_footer {
        max-width: 6rem;
        margin-bottom: 5px;
    }
}