.page_content {
    margin-bottom: 100px;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    overflow-x: hidden;
}

.page_content_parent {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, auto); 
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.page_title_category {
    font-size: 40px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
}

.page_content_flex {
    margin-top: 20px;
    margin-left: 30px;
    grid-area: 1 / 1 / 3 / 2;
    margin-bottom: 100px;
}

.page_content_rightpage_block {
    margin-top: 20px;
    margin-right: 50px;
    margin-left: 15px;
    grid-area: 1 / 2 / 2 / 3;
}

.page_content_rightpage {
    padding: 30px;
    border-radius: 30px;
    margin-top: 10px;
    margin-bottom: 50px;
    background-color: rgba(0, 0, 0, 0.054);
}

.page_content_title_info {
    font-size: 25px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    margin-bottom: 50px;
}

.page_content_title {
    background-color: rgb(66, 133, 235);
    color: #ffffff;
    padding: 80px;
    font-size: 70px;
    font-family: InterTight-Regular;
    position: relative;
    animation-name: example;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    width: 100%;
    box-sizing: border-box;
}

@keyframes example {
    from {
        top: -30px;
    }
    to {
        top: 0px;
    }
}

.page_title_icon {
    width: 100px;
    margin-bottom: 10px;
    margin-right: 25px;
}

* {
    box-sizing: border-box;
}

@keyframes example {
    from {
        top: -30px;
    }

    to {
        top: 0px;
    }
}

.page_title_icon {
    width: 100px;
    margin-bottom: 10px;
    margin-right: 25px;
}

@media (max-width: 1280px) {
    .page_content_flex {
        width: 33vw;
        margin-left: 10px;
    }
    .page_content_title {
        padding: 50px;
        font-size: 70px;
    }
    .card_list {
        width: 100%;
    }
    .page_title_category {
        font-size: 30px;
    }
    .page_content_title_info {
        font-size: 20px;
    }
}

@media (max-width: 1024px) {
    .page_content {
        margin-bottom: 50px;
    }
    .page_content_parent {
        grid-template-columns: 1fr; 
        grid-template-rows: auto auto; 
    }
    .page_content_flex {
        grid-area: 1 / 1 / 2 / 2; 
        margin: 0px; 
        width: 100vw;
    }
    .page_content_rightpage_block {
        grid-area: 2 / 1 / 3 / 3; 
        margin: 0 40px; 
    }
    .page_content_title {
        padding: 40px;
        font-size: 50px;
    }
    .page_content_title_info {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .page_content_title {
        padding: 20px;
        padding-bottom: 30px;
        font-size: 33px;
        line-height: 13px;
        text-align: center;
    }
    .page_title_category {
        font-size: 28px;
    }
    .page_content_title_info {
        font-size: 18px;
        margin-bottom: 20px;
    }
}

@media (max-width: 576px) {
    .page_content {
        margin-bottom: 30px;
    }
    .page_title_icon {
        display: flex;
        margin: 10px auto;
        justify-content: center;
    }
    .page_content_title {
        line-height: 30px;
        text-align: center;
        display: grid;
        margin: 0px auto;
        justify-content: center;
        font-size: 30px;
    }
    .page_title_category {
        font-size: 22px;
    }
    .page_content_rightpage_block {
        margin-inline: 20px;
    }
    .page_content_rightpage {
        padding: 20px;
    }
    .page_content_title_info {
        font-size: 17px;
        font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    }
}
