/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Authorization.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Authorization.css
 *	@author: Манжос Геннадий Юрьевич,     Горбас Александр Петрович, Белов Михаил Александрович
*/
.registration_page {
    background-color: rgb(246, 246, 246);
    min-height: 1200px;
}

.registration_grid {
    background: radial-gradient(circle farthest-corner at center center, #8cebfe 0%, #ffffff00 50%);
    margin: 0px auto;
    width: auto;
    height: auto;
    padding: 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.registration_title {
    font-size: 20px;
    color: rgb(70, 70, 70);
    font-family: "InterTight-Regular", Arial, Helvetica, sans-serif;
    grid-area: 1 / 1 / 2 / 2;
}

.registration_title_text {
    font-size: 40px;
    top: -100px;
    position: relative;
    line-height: 50px;
    width: 600px;
    color: rgb(70, 70, 70);
    font-family: "InterTight-Regular", Arial, Helvetica, sans-serif;
    grid-area: 2 / 1 / 3 / 2;
}

.spline_scene_demo_reg {
    -webkit-animation: spline_scene_demo_reg 2s cubic-bezier(.55, .085, .68, .53) both;
    animation: spline_scene_demo_reg 2s cubic-bezier(.55, .085, .68, .53) both;
    width: 50%;
    margin-left: auto;
    margin-top: -350px;
}

.reg_pic {
    position: absolute;
    margin-top: 440px;
    margin-left: 310px;
    width: 440px;
    left: auto;
    z-index: 0;
}

.registration_form {
    backdrop-filter: blur(15px) saturate(200%);
    -webkit-backdrop-filter: blur(15px) saturate(200%);
    background-color: rgba(255, 254, 254, 0.345);
    border: 1px solid rgba(209, 213, 219, 0.3);
    border-radius: 30px;
    width: 500px;
    height: fit-content;
    padding: 40px;
    grid-area: 1 / 2 / 3 / 3;
    z-index: 1;
    left: auto;
}

.registration_form_welcome {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    font-size: 40px;
    font-family: "InterTight-SemiBold", Arial, Helvetica, sans-serif;
    color: rgba(79, 79, 79, 1);
    animation: fadeIn 3s ease;
}

.registration_form_input {
    font-family: "InterTight-Regular", Arial, Helvetica, sans-serif;
    padding-top: 30px;
    color: #525252;
}

.input_form {
    border: none;
    display: flex;
    font-size: 18px;
    font-family: "InterTight-Regular", Arial, Helvetica, sans-serif;
    background-color: rgb(219, 219, 219);
    color: #333333;
    padding: 15px;
    height: 60px;
    border-radius: 15px;
    width: 100%;
    margin: 5px auto;
    transition: all 0.2s ease-in-out;
}

.input_form.select {
    padding: 0;
}

.input_form.select * {
    max-width: 318px;
}

.input_form:active {
    border: none;
    outline: none;
    background-color: rgba(74, 79, 91, 0.221);
}

.input_form:focus {
    border: none;
    outline: none;
    background-color: rgba(90, 110, 157, 0.221);
}

.registration_form_hint {
    font-size: 17px;
    color: #4a4a4a;
    display: block;
    font-family: "InterTight-Regular", Arial, Helvetica, sans-serif;
    text-align: center;
    margin-top: 20px;
    height: 130px;
}

.registration_form_hint .errors {
    color: inherit;
    padding-top: 10px
}

.authorization_link {
    padding: 5px;
    color: #525252;
    text-decoration: none;
}

.authorization_button {
    display: block;
    color: #333333;
    cursor: pointer;
    text-align: center;
    font-family: "InterTight-Regular", Arial, Helvetica, sans-serif;
    border-radius: 30px;
    margin: 0 auto 0 auto;
    width: 200px;
    padding: 15px;
    font-size: 21px;
    background-color: #ffad77;
    transition: all 0.1s ease-in-out;
    border: 0;
}

.authorization_button:disabled {
    cursor: default;
    background-color: #dbdbdb;
}

.authorization_button:hover:not(:disabled) {
    background-color: #9bb9c0;
}

.authorization_button_moodle {
    display: block;
    color: #333333;
    cursor: pointer;
    text-align: center;
    font-family: "InterTight-Regular", Arial, Helvetica, sans-serif;
    font-size: 16px;
    border-radius: 30px;
    border: 0;
    margin: 20px auto;
    padding: 15px 15px 15px 55px;
    transition: all 0.1s ease-in-out;
    background-image: url('/public/icons/moodle_icon_white.png');
    background-size: 15%;
    background-position: left;
    background-position-x: 0.7rem;
    background-repeat: no-repeat;
    background-color: #ffad77;
}

.authorization_button_moodle:disabled {
    cursor: default;
    background-color: #dbdbdb;
}

.authorization_button_moodle:hover:not(:disabled) {
    background-color: #9bb9c0;
}

@media(max-width: 1200px) {    
    .registration_page {
        height: auto;
        min-height: 98%;
    }
    .registration_grid {
        display: flex;
        flex-direction: column;
        width: auto;
        grid-row-gap: 50px;
        padding: 100px;
        position: relative;
    }
    .registration_title {
        order: 1;
        font-size: 20px;
        color: rgb(70, 70, 70);
        font-family: "InterTight-Regular", Arial, Helvetica, sans-serif;
        text-align: center;
    }
    .registration_title_text {
        order: 2;
        width: fit-content;
        line-height: 30px;
        font-size: 25px;
        top: 0;
        height: 75px;
        text-align: center;
    }
    .spline_scene_demo_reg {
        order: 4;
        position: absolute;
        width: 20%;
        height: 20%;
        max-width: 60%;
        box-sizing: border-box;
        margin-top: 0px;
        top: 350px;
        left: 5px;
        z-index: 1;
    }
    .registration_form {       
        order: 3;
        position: relative;
        z-index: 10;
        left: 400px;
        width: 50%;
        height: auto;
        margin-top: 20px;   
        margin: 5px;
        padding: 20px;
    }
    .registration_form_welcome{
	    font-size: 30px;
    }
}

@media(max-width: 928px) {
    .spline_scene_demo_reg {
        top: 200px;
        left: -60px;
        padding: 50px;
    }
    .registration_form {
        left: 150px;
        min-width: 80%;
        max-width: 80%;
        height: auto;
    }
}

@media (max-width: 648px) {    
    .registration_page {
        height: auto;
        min-height: 100%;
    }
    .registration_grid {
        padding: 30px;
    }
    .registration_title_text {
        width: fit-content;
        line-height: 30px;
        font-size: 25px;
        top: 0;
        height: 75px;
    }
    .spline_scene_demo_reg {
        left: -60px;
        padding: 50px;
        top: 100px;
    }
    .registration_form {
        left: unset;
        max-width: 100%;
        min-width: 100%;
    }
    .registration_form_hint {
        height: 50px;
    }
}

@media only screen and (max-width: 600px) {
    .registration_form_welcome {
        font-size: 35px;
    }
}

@media only screen and (max-width: 468px) {
    .spline_scene_demo_reg {
        display: none;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes spline_scene_demo_reg {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0
    }

    100% {
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1
    }
}