/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Start_Page.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Start_Page.css
 *	@author: Манжос Геннадий Юрьевич,     Горбас Александр Петрович
*/
.start_page-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box; 
}

.start_page-glass-content {
    top: 50%;
    left: 50%;
    transform: translate(0, 0);
    width: 65vw;
    max-width: 50em;
    backdrop-filter: blur(20px) saturate(200%);
    -webkit-backdrop-filter: blur(20px) saturate(200%);
    background-color: rgba(194, 151, 151, 0.152);
    border-radius: 2em;
    border: 1px solid rgba(209, 213, 219, 0.3);
    padding: 2em;
    box-sizing: border-box;
    -webkit-animation: start_page-title 1s cubic-bezier(.55, .085, .68, .53) both;
    animation: start_page-title 1s cubic-bezier(.55, .085, .68, .53) both;    
    justify-content: space-evenly;
}

.spline_scene {
    display: grid;
    justify-content: center;
    position: relative;
    padding: 25px;
}

.start_page-title {
    color: rgb(173, 37, 37);
    text-align: center;
    font-size: 50px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    -webkit-animation: start_page-title 1s cubic-bezier(.55, .085, .68, .53) both;
    animation: start_page-title 1s cubic-bezier(.55, .085, .68, .53) both
}

.start_page-title-desc {
    color: rgb(51, 51, 51);
    font-size: 20px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    -webkit-animation: start_page-title 1s cubic-bezier(.55, .085, .68, .53) both;
    animation: start_page-title 1s cubic-bezier(.55, .085, .68, .53) both
}

.start_page-button {
    margin: 40px auto;
    display: block;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 50px;
    color: rgb(255, 255, 255);
    background-color: rgba(198, 198, 198, 0);
    padding: 20px;
    width: 200px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 25px;
    transition: background-color 0.3s ease, color 0.3s ease;
    -webkit-animation: start_page-title 1s cubic-bezier(.55, .085, .68, .53) both;
    animation: start_page-title 1s cubic-bezier(.55, .085, .68, .53) both;
}

.start_page-button:hover {
    color: rgb(110, 110, 110);
    background-color: rgb(249, 249, 249);
}

.start_page-video {
    filter: blur(7px);
    transform: scale(1.1);
}

#background-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; 
    height: 100vh;
    z-index: -1;
    object-fit: cover;
    pointer-events: none;
}

@media (min-width: 1024px) and (max-height: 600px) {
    .spline_scene {
        display: none;
    }
}

@media (max-width: 1200px) {
    .start_page-content {
        padding: 15px;
    }
}

@media (max-width: 768px) {
    .start_page-content {
        padding: 10px;
        height: 90vh;
    }
    .start_page-pic {
        max-width: 80vw;
        max-height: 20vh;
        display: flex;
        justify-content: center; 
        align-items: center;
        margin: 0 auto;
    }
    .spline_scene {
        transform: scale(0.6);
        margin: 10px;
    }
    .start_page-button {
        padding: 18px;
        width: 180px;
        font-size: 24px;
        transition: background-color 0.25s ease, color 0.25s ease;
    }
}

@media (max-width: 576px) {
    .start_page-content {             
        margin: 10px;
        padding: 5px;
    }
    .start_page-glass-content {   
        width: 80vw;
        border-radius: 1em;
    }
}

@media (max-width: 479px) {
    .start_page-content {
        background-color: rgba(215, 76, 76, 0);    
        height: 60vh;
    }
    .start_page-glass-content {
        border: none;
        top: 180px;
        border-radius: 20px;
        height: fit-content;
        padding: 30px;
    }
    .spline_scene {
        display: none;
    }
    .start_page-title {
        font-size: 30px;
        margin-left: 0px;
        margin: 0px auto;
        justify-content: center;
        line-height: 25px;
    }
    .start_page-title-desc {
        margin-top: 50px;
        font-size: 16px;
        text-align: center;
        line-height: normal;
    }
    .start_page-button {
        padding: 15px;
        width: 160px;
        font-size: 22px;
        transition: background-color 0.2s ease, color 0.2s ease;
        margin-bottom: 15px;
        -webkit-animation-duration: 0.5s;
        animation-duration: 0.5s;
    }
}

@keyframes rotateY {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(360deg);
    }
}

@-webkit-keyframes rotateY {
    0% {
        -webkit-transform: rotateY(0deg);
    }

    100% {
        -webkit-transform: rotateY(360deg);
    }
}

@-webkit-keyframes start_page-pic {
    0% {
        -webkit-transform: translateY(-100vh) scaleY(2.5) scaleX(0.2);
        transform: translateY(-100vh) scaleY(2.5) scaleX(0.2);
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes start_page-pic {
    0% {
        -webkit-transform: translateY(-100vh) scaleY(2.5) scaleX(0.2);
        transform: translateY(-100vh) scaleY(2.5) scaleX(0.2);
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

@-webkit-keyframes start_page-title {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0
    }

    100% {
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1
    }
}

@keyframes start_page-title {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0
    }

    100% {
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1
    }
}