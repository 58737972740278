.error-page {
    background-color: #aaaaaa;
    width: 100%;
    height: 100vh;
    padding-top: 1rem;
}

.error-dialog {
    background-color: white;
    color: black;
    border-radius: 10px;
    border: 1px solid rgb(153, 153, 153);
    box-shadow: 5px 5px 15px rgb(97, 97, 97);
    margin: auto;
    width: 40%;
    text-align: center;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.error-icon {
    align-self: center;
    border-radius: 50%;
    background-color: #fde1e1;
    color: #dc2828;
    font-size: 2.4rem;
    font-weight: 500;
    padding: 0;
    user-select: none;
    width: 64px;
    height: 64px;
}

.error-title {
    color: black;
    font-size: 1.4rem;
    user-select: none;
}

.error-message {
    color: #6c6e75;
    font-size: 1.2rem;
}

.error-button {
    border-radius: 5px;
    border-style: none;
    width: 100%;
    background-color: #dc2626;
    color: white;
    padding: 0.5rem;
}

.error-button:hover {
    background-color: #df4d4d;
}

@media (max-width: 600px) {
    .error-page {
        padding-top: 0;
    }

    .error-dialog {
        width: 100%;
        border-radius: 0;
        border: none;
        row-gap: 2rem;
        padding: 0;
        padding-top: 1rem;
    }

    .error-title {
        font-size: 2.5rem;
    }

    .error-message {
        font-size: 2rem;
    }

    .error-button {
        font-size: 1.5rem;
        border-radius: 0;
    }
}