/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: SidebarCategoriesDisplay.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. SidebarCategoriesDisplay.css
 *	@author: Горбас Александр Петрович
*/

.card_list {
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    background-color: rgba(220, 220, 220, 0.2); 
    border-radius: 10px;
    padding: 10px;
    width: 440px; 
}

.card_flex {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.card_items {
    color: rgb(56, 56, 56);
    text-align: left;
    cursor: pointer;
    margin: 2px;
    font-size: 20px;
    border-radius: 10px;
    width: 100%; 
    padding: 15px 20px; 
    border: none;
    animation: fadeIn 0.6s ease forwards;
    opacity: 0;
    color: rgb(56, 56, 56);
    background-color: rgb(223, 239, 251, 1);    
    transition: background-color 0.3s ease, color 0.3s ease;
}
.card_items.active {
    background-color: rgb(187, 218, 255, 1);
    color: rgb(4, 46, 101);
}

.card_items:hover {
    background-color: transparent; 
    color: rgb(56, 56, 56); 
}

.card_items[data-has-children="true"]:hover {
    background-color: rgb(223, 239, 251);
    color: rgb(30, 76, 136);
}

@keyframes fadeIn {
    from {
        transform: translateY(-15px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.subcategories_container {
    background-color: transparent; 
    border-radius: 10px;
    margin-top: 5px; 
    padding: 0 0 0 20px;
    width: 100%;
}

.subcategories_container .card_items {
    background-color: transparent; 
    width: 100%; 
    padding: 15px 20px;
    margin: 2px 0;
    font-size: 1.2rem;
    color: rgb(1, 21, 46);
}
.subcategories_container .card_items[data-level="1"] {
    background-color: rgba(187, 218, 255, 0.6);
}

.subcategories_container .card_items[data-level="2"] {
    background-color: rgba(187, 218, 255, 0.4);
}

.subcategories_container .card_items[data-level="3"] {
    background-color: rgb(187, 218, 255, 0.2);
}

.subcategories_container .card_items[data-has-children="false"] {
    background-color: transparent;
}

.subcategories_container .card_items[data-has-children="false"]:hover {
    color: rgb(31, 128, 255); 
}

.subcategories_container .card_items[data-has-children="true"]:hover {
    background-color: rgb(223, 239, 251);
}

.sidebar-toggle {
    display: none; 
    padding: 10px;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
}

@media (max-width: 1280px) {
    .card_list.visible {
        padding: 10px 40px;
    }
    .card_items,
    .subcategories_container .card_items{
        font-size: 1rem;
        padding: 10px 15px;
    }
}

@media (max-width: 1024px) {
    .sidebar-toggle {
        display: block;
    }
    .card_list {
        display: none; 
    }
    .card_list.visible {
        display: block; 
    }
    .sidebar-toggle{
        border-radius: 10px;
    }
    .sidebar-toggle{
        font-size: 1rem;
        margin: 10px 15px;
    }
}

@media (max-width: 768px) {
    .sidebar-toggle,
    .card_items,
    .subcategories_container .card_items{
        font-size:1rem;
        padding: 10px 15px;
    }
}
@media (max-width: 576px) {
    .card_list.visible {
        padding-inline: 20px;
    }
    .sidebar-toggle,
    .card_items,
    .subcategories_container .card_items{
        font-size: 0.9rem;
        padding: 10px 15px;
    }
}