.radiology_appeals_wrapper {
    padding: 0;
}

.radiology_appeal_header_items {
    display: flex;
    padding: 0;
    border-bottom: 1px solid rgb(219, 228, 255);
}

.radiology_appeal_header_item {
    margin-right: 8px;
    margin-top: 8px;
    border: 1px solid rgba(177, 181, 218, 0.463);
    background-color: rgb(219, 228, 255);
    color: rgb(0, 47, 255);
    padding: 10px;
    font-size: 18px;
    width: fit-content;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    border-radius: 20px 20px 0 0;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
}

.radiology_appeal_header_item:hover {
    background-color: rgb(194, 203, 232);
}

.radiology_appeal_header_item.active {
    background-color: rgb(75, 108, 255);
    color: white;
}