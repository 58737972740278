/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: AccountPage.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Файлы относящиеся к страницам сайта "мой профиль". AccountPage.css
 *	@author: Манжос Геннадий Юрьевич, Белов Михаил Александрович
*/
.profile {
    padding: 0px;
}

.profile .header {
    font-size: 4rem;
    background-color: #516ae4;
    color: white;
    padding: 30px;
}

.profile .header .small {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    margin-top: 20px;
    width: 500px;
    font-size: 17px;
}

.profile .header .title {
    margin-left: 20px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 65px;
}

.profile .content {
    min-height: 50vh;
    display: flex;
}

.profile .content .menu {
    width: 350px;
    padding-top: 10px;
    background-color: #f1f4fb;
    font-size: 21px;
    font-family: Inter-SemiBold, Arial, Helvetica, sans-serif;
    min-height: 50vh;
}

.profile .content .menu button {
    margin: 2px;
    text-align: start;
    width: 330px;
    border: none;
    background-color: #ffe4c400;
    margin-left: 10px;
    border-radius: 20px;
    color: #373737;
    padding: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.profile .content .menu button:hover {
    color: #006eff;
    background-color: #ffffff91;
}

.profile .content .menu button.active {
    color: #006eff;
    background-color: #ffffff;
}

.profile .content .block {
    padding: 50px;
    min-height: 50vh;
    width: 850px;
    font-family: Inter-Regular, Arial, Helvetica, sans-serif;
    font-size: 20px;
}

.profile .heading {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.profile .profile-info .group {
    margin: 1rem 0;
    padding: 1rem;
    border-style: none;
    border-radius: 20px;
    background-color: white;
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.profile .profile-info .group.university {
    background-color: #0a6398;
    background-image: url('/public/icons/account_bg_university.png');
    background-repeat: no-repeat;
    background-position: 30rem -3rem;
    background-size: 50%;
    color: white;
}

.profile .profile-info .group.moodle {
    background-color: #f2a96d;
    background-image: url('/public/icons/moodle_icon_white.png');
    background-repeat: no-repeat;
    background-position: 31rem 1rem;
    background-size: 35%;
    color: white;
}

.profile .profile-info .group .heading {
    font-size: 1.8rem;
    user-select: none;
}

.testing_results .entry {
    border-radius: 10px;
    background-color: #f9f9f9;
    padding: 0;
    margin-bottom: 10px;
}

.testing_results .entry .head {
    color: #1955f8;
    font-size: 1.3rem;
    border-radius: 10px;
    padding: 1rem;
    background-color: #d7e4ff;
    display: flex;
    justify-content: space-between;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
}

.testing_results .entry .head .actions {
    margin-top: 10px;
}

.testing_results .entry .head .title_and_date .date {
    font-size: 0.9rem;
    color: #6e6e6e;
}

.testing_results .entry .data {
    font-size: 1rem;
    max-height: 30vh;
    overflow: scroll;
    padding: 1rem;
    color: #959595;
}

.testing_results .entry .data .row {}

.testing_results .entry .data .row .title {
    margin-top: 1rem;
    color: #959595;
}

.testing_results .entry .data .row .answers {
    color: #000000;
    font-size: 0.8rem;
    margin-bottom: 1rem;
}

@media (max-width: 1200px) {
    .profile .content .block {
        width: 100%;
    }
    .profile .content .menu {
        width: 34vw;
        font-size: 19px;
    }
    .profile .content .menu button {
        width: 32vw;
    }
    .col-3.text-body-secondary {
        width: 50%;
    }
    .profile .profile-info .group.university {
        background-position: 16rem 1.3rem;
        background-size: 70%;
    }
    .profile .profile-info .group.moodle {
        background-position: 16rem 2rem;
        background-size: 50%;
    }
}

@media (max-width: 780px) {
    .profile .heading {
        font-size: 2rem;
    }
    .profile .header .title {
        font-size: 50px;
    }
    .profile .header img {
        width: 70px;
        height: 70px;
    }
    .profile .profile-info .row {
        flex-direction: column;
    }
}

@media (max-width: 678px) {
    .profile .heading {
        width: 100%;
        font-size: 2rem;
    }
    .profile .header .small {
        width: 100%;
        font-size: 17px;
    }
    .profile .content {
        flex-direction: column;
        width: 100%;
    }
    .profile .header {
        width: 100%;
        height: fit-content;
    }
    .profile .content .block {
        padding-left: 2.7rem;
        padding-right: 1.3rem;
        min-height: 50vh;
        width: 100%;
        font-size: 20px;
        margin-top: 30px;
        margin-bottom: 80px;
    }
    .profile .content .menu {
        max-width: 100%;
        width: 100%; 
        min-height: 70px; 
        display: flex;
        justify-content: space-around; 
        
        position: relative; 
        padding: 10px; 
        font-size: 17px;
    }
    .profile .content .menu button {
        width: 70vw; 
        margin-left: 0; 
        padding: 10px; 
        text-align: center;
    }
    .profile .profile-info .row {
        flex-direction: row;
    }
}

@media (max-width: 478px) {
    .profile .content .menu {
        flex-direction: column;
        align-items: center
    }
    .profile .header {
        font-size: 3.5rem;
    }
    .profile .header .title {
        font-size: 10vw;
        line-height: 50px;
    }
    .profile .header img {
        width: 48px;
        height: 48px;
    }
    .profile .heading {
        font-size: 1.5rem;
    }
    .profile .profile-info .row {
        flex-direction: column;
    }
}