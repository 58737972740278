/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Endoscopy_Data_Images_Display.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "эндоскопия". В этом файле находится компонент, отвечающий за вывод всех картинок в кейсе.
 *	@author: Манжос Геннадий Юрьевич
*/
@media only screen and (max-width: 479px) {
    .case_image_endoscopy {
        border-radius: 20px;
    }
}