/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: All_Pages_Data_Entrie_Page.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. В этом файле находится содержимое страницы, которая выводится при переходе на страницу кейса конкретной категории.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
.flex_container {
    user-select: none;
    height: 100%;
    max-width: 1000px;
    margin: auto;
    padding: 30px;
    border-radius: 15px;
    background-color: rgb(248, 248, 248);
    display: block;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-bottom: 500px;
}

.keyboard_back,
.keyboard_next {
    margin-left: 5px;
    margin-right: 5px;
    width: 45px;
}

.mouse_icon {
    margin-right: 5px;
}

.birth_and_gender_grid {
    display: grid;
    grid-template-columns: repeat(2, 400px);
    grid-template-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    margin-bottom: 10px;
}

.case_gender {

    grid-area: 1 / 1 / 2 / 2;
}

.case_birth {
    grid-area: 1 / 2 / 2 / 3;
}

@media only screen and (max-width: 600px) {
    .flex_container {
        padding: 15px;
        max-width: fit-content;
        margin: auto;
        border-radius: 15px;
        background-color: rgba(240, 240, 240, 0);
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        margin-top: 0px;
        margin-bottom: 500px;
    }

    .birth_and_gender_grid {
        display: flex;
        flex-direction: column;
    }

}

.ecg_slider_info {
    border-left: 2px solid rgb(35, 123, 255);
    background-color: rgb(223, 235, 255);
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 16px;
    padding: 15px;
    margin-bottom: 10px;

}

.ecg_slider_title {
    color: rgb(39, 114, 255);
    font-size: 25px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;

}


.flex_item_1 {
    grid-area: 1 / 1 / 2 / 2;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    color: rgb(43, 43, 43);
    font-size: 40px;
}

.top_case_navigation_block {
    grid-area: 1 / 2 / 2 / 3;
}

@media only screen and (max-width: 600px) {

    .ecg_slider_info {
        font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
        font-size: 15px;
        padding: 15px;
        margin-bottom: 10px;

    }

    .ecg_slider_title {
        color: rgb(39, 114, 255);
        font-size: 18px;
        margin-bottom: 15px;
        font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;

    }

    .flex_item_1 {
        grid-area: 1 / 1 / 2 / 2;
        font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
        color: rgb(43, 43, 43);
        width: 350px;
        font-size: 18px;
        margin-bottom: 20px;
        height: fit-content;
    }


    .flex_container_title {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }


    .top_case_navigation_block {
        grid-area: 2 / 1 / 3 / 2;
        margin-bottom: 15px;
    }

}

.flex_container_title {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 10px;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}


.flex_container_title_info {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: rgb(43, 43, 43);

    margin-bottom: 20px;
}


.case_gender_block {
    border: 1px solid rgba(219, 219, 219, 0.494);
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    color: rgb(43, 43, 43);
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 27px;
    padding: 15px;
    height: 100%;

}


.case_birth_date {
    border: 1px solid rgba(219, 219, 219, 0.494);
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    color: rgb(43, 43, 43);
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    font-size: 70px;
    padding: 10px;
    height: 100%;
}


@media only screen and (max-width: 600px) {

    .case_gender {
        font-size: 20px;

    }

    .case_birth_date {
        font-size: 20;
    }
}

.case_text_info {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    width: fit-content;
    font-size: 17px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: rgb(25, 106, 237);

}

@media only screen and (max-width: 600px) {

    .case_text_info {
        padding-left: 0px;
        font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
        font-size: 17px;
        color: rgb(43, 43, 43);
        margin-bottom: 0px;

    }
}

.case_text_block {
    border-radius: 15px;
    border: 1px solid rgba(219, 219, 219, 0.494);
    background-color: rgb(255, 255, 255);
    padding: 15px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: rgb(43, 43, 43);
    height: 100%;

}

.case_text_rec {
    margin-left: 10px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: rgb(43, 43, 43);
}


.case_text_block_bold {
    border: 1px solid rgba(219, 219, 219, 0.494);
    border-radius: 15px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 20px;
    padding: 15px;
    color: rgb(43, 43, 43);
    background-color: rgb(255, 255, 255);
}


.case_flex {
    gap: 5px;
    display: flex;
    margin-bottom: 15px;
}

@media only screen and (max-width: 600px) {
    .case_flex {
        display: grid;
        justify-content: space-between;
    }
}

.case_description {
    margin-bottom: 30px;
}

.case_results:empty {
    display: none;
}

.case_images {
    display: flex;
    gap: 3px;
}

.case_image img {
    width: 300px;

}

.case_icon {
    margin-bottom: 10px;
}


.case_preview_latin_name1 {
    color: rgb(80, 80, 80);
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
}

.case_bold_name {
    font-size: 30px;
    color: rgb(39, 124, 236);
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;

}

@media only screen and (max-width: 600px) {
    .case_images {
        display: grid;
        gap: 2px;
    }


    .case_image img {
        width: 315px;
    }
}


@media only screen and (max-width: 600px) {

    .case_bold_name {
        margin-left: 0px;
        font-size: 30px;
    }


    .case_preview_latin_name1 {
        margin-bottom: 50px;
    }
}