/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Pimu_Categories_Page.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. В этом файле находится содержимое страницы, которая выводится при переходе на страницу категории.
 *	@author: Манжос Геннадий Юрьевич,   Горбас Александр Петрович, Белов Михаил Александрович
*/
.custom-background_menu {
    min-height: 100vh;
    background-size: cover;
    background-color: rgb(225, 230, 235);
}

.card_list-list {
    user-select: none;
    margin-top: 15px;
    margin: auto;
    max-width: 1500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 300px;
    /* grid-row-gap: 5px; */
}

@media only screen and (max-width: 1300px) {
    .card_list-list {
        line-height: 20px;
        grid-template-columns: repeat(1, 0fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        padding-bottom: 100px;
    }
}

.pimu_card-items {
    width: clamp(50px, 40%, 350px);
    background-color: rgba(179, 179, 179, 0.109);
    margin: 1px;
    height: 350px;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: background-color 0.1s ease;
}

.pimu_card-items.dark {
    cursor: default;
    transition: none;
    background-color: rgb(225, 230, 235);
}

.pimu_card-items.dark > * {
    opacity: 0.2;
    transition: none;
}

@media only screen and (max-width: 883px) {
    .card_list-list {
        margin-inline: 20px;
    }
    .pimu_card-items {
        width: 260px;
        height: 260px;
    }
}

@media only screen and (max-width: 600px) {
    .pimu_card-items {
        width: 190px;
        background-color: rgba(169, 169, 169, 0.158);
        margin: 1px;
        height: 190px;
        border-radius: 30px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: background-color 0.1s ease;
    }
}

@media only screen and (max-width: 422px) {
    .pimu_card-items {
        width: 150px;
        height: 150px;
    }
}

.pimu_card-items:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

.pimu_card-icon {
    display: flex;
    justify-content: center;
}

.pimu_card-icon img {
    width: 55%;
    transition: all 0.15s cubic-bezier(0.65, 0, 0.35, 1);
}

@media only screen and (max-width: 600px) {
    .pimu_card-icon img {
        width: 65%;
    }
}

.pimu_card-items:not(.dark) .pimu_card-icon img:hover {
    transform: scale(1.12);
}

@media only screen and (max-width: 600px) {
    .pimu_card-icon img:hover {
        transform: none;
    }
}

.pimu_card-name {
    text-align: center;
    font-size: 25px;
    line-height: 25px;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    color: rgb(100, 100, 100);
    justify-content: center;
    width: 250px;
}

@media only screen and (max-width: 600px) {
    .pimu_card-name {
        font-size: 14px;
        line-height: 15px;
        text-align: center;
        width: 100px;
    }
}

.pimu_title {
    user-select: none;
    color: rgb(69, 69, 69);
    text-align: center;
    font-size: 75px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    padding-top: 30px;
    padding-bottom: 30px;
    animation: customAni 0.4s ease 0s normal both;
}

@media only screen and (max-width: 600px) {
    .pimu_title {
        text-align: center;
        font-size: 35px;
    }
}

@keyframes customAni {
    0% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(5px);
    }
}