/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: ReportMistakeDialog.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. ReportMistakeDialog.css
 *	@author: Белов Михаил Александрович
*/
.dialog_background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    backdrop-filter: blur(5px);
}

.dialog_report_mistake {
    width: 50%;
    margin: 10rem auto;
    height: 50vh;
    background-color: white;
    padding: 20px;
    font-size: 24px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dialog_report_mistake .dialog_header {
}

.dialog_report_mistake .dialog_header .large {
    font-size: 3.2rem;
    color: rgb(50, 50, 50);
}

.dialog_report_mistake .dialog_header .small {
    font-size: 1.2rem;
    color: rgb(119, 119, 119);
}

.dialog_report_mistake .dialog_body textarea {
    width: 100%;
    border-radius: 20px;
    font-size: medium;
    min-height: 10vh;
    resize: none;
}

.dialog_report_mistake .dialog_body {
    padding: 2rem 0;
}

.dialog_report_mistake .dialog_footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 2rem;
}

.dialog_report_mistake .dialog_footer button {
    border-radius: 20px;
    border-style: none;
    padding: 1rem;
}