/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: DZI_ImageViewer.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. DZI_OpenSeadragon DZI_ImageViewer.css
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович
*/
.openseadragon {
  margin-top: 10px;
  border: 1px solid rgba(0, 0, 0, 0.103);
  overflow: auto;
  margin-bottom: 100px;
  width: auto;
  height: 900px;
  border-radius: 15px;

}


.openseadragon.front-page {
  height: 586px;
  background-color: #2e79da;
}

.openseadragon-small {
  width: 100px;
  height: 80px;
  border: 2px solid black;
  color: #333;
  background-color: black;
}

@media only screen and (max-width: 600px) {
  .openseadragon {

    margin-bottom: 20px;
    height: 500px;
    border-radius: 30px;
  }

}


.openseadragon_dzi {
  margin-top: 10px;
  overflow: auto;
  margin-bottom: 15px;
  width: auto;
  height: 400px;
  border-radius: 5px;

}