/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Endoscopy_Data_EntriesList.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "эндоскопия". В этом файле находится компонент, отвечающий за вывод списка кейсов конкретной категории.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович
*/
.flex_container_gastroscopy {
    cursor: pointer;
    display: flex;
    padding: 15px;
    margin: 5px;
    width: auto;
    align-items: flex-start;
    background-color: rgba(247, 247, 247, 0);
    border-radius: 20px;
    transition: background-color 0.2s ease;
}

.gastroscopy_page {}


.gastroscopy_block {
    padding: 5px;
    border-radius: 20px;
    background-color: rgba(226, 226, 226, 0.137);
}

.flex_container_gastroscopy:hover {
    background-color: rgb(207, 223, 253);
}


.case_id_number {
    margin-left: 20px;
    font-size: 18px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    color: rgb(0, 132, 255);
    margin-right: 20px;
}

.case_id_circle {
    margin-right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(231, 231, 231);
    display: flex;
    align-items: center;
    justify-content: center;
}

.record_description {
    /* width: 100px; */
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 20px;
    font-size: 20px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    color: rgb(49, 49, 49);
}

.gastroscopy_flex_container {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.gastroscopy_title_items {
    font-size: 35px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    color: rgb(49, 49, 49);
    padding: 10px;
}

.gastroscopy_title_icon {
    width: 60px;
}

.gastroscopy_table_column {
    color: rgb(49, 49, 49);
    font-size: 15px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    margin: 5px;
    margin-right: 160px;
}

.demo_version_endoscopy {
    background-color: rgb(35, 116, 255);
    border-radius: 25px;
    padding: 7px;
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 479px) {
    .gastroscopy_page {
        height: fit-content;
    }


    .gastroscopy_block {
        padding: 0px;
        border-radius: 0px;
        background-color: rgba(226, 226, 226, 0);
    }


    .flex_container_gastroscopy {
        cursor: pointer;
        display: flex;
        padding: 10px;
        margin: 2px;
        width: 330px;
        align-items: flex-start;
        background-color: rgba(238, 238, 238, 0.738);
        border-radius: 20px;
        transition: background-color 0.2s ease;
    }

    .record_description {
        width: auto;
        flex-grow: 1;
        white-space: unset;
        overflow: hidden;
        text-overflow: unset;
        margin-left: 0px;
        font-size: 16px;
        font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
        color: rgb(49, 49, 49);
        padding: 5px;
    }


    .demo_version_endoscopy {
        background-color: rgb(35, 116, 255);
        border-radius: 10px;
        padding: 7px;
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    }

    .gastroscopy_title_items {
        font-size: 22px;
        font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
        color: rgb(49, 49, 49);
        padding: 10px;
    }

    .gastroscopy_title_icon {
        width: 32px;
    }

}