/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Search_Bar.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Search_Bar.css
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
.search_block {
    margin: 20px;
}

.search_block_biology {
    margin: 0px auto;
    width: 1070px;
    padding: 20px;
}

.search_block_title {
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    color: rgb(82, 82, 82);
    font-size: 30px;
}

.search_block_bar {
    width: 500px;
    font-size: 18px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    color: rgb(47, 47, 47);
    height: 50px;
    padding-left: 50px;
    padding-right: 20px;
    margin: 10px 5px 10px 0px;
    border-radius: 30px;
    border: 1px solid rgba(148, 148, 148, 0.216);
    transition: border-color 0.3s ease-in-out;
}

.search_block_bar.invalid {
    border-color: #ffc8c8;
    background-color: #ffdada;
    transition: background-color 0.3s ease-in-out;
}

.search_block_bar.invalid:focus {
    border-color: #ffc8c8;

}

.search_block_bar:focus {
    outline: none;
}

.search_block_button {
    border-radius: 25px;
    border: none;
    width: 120px;
    height: 50px;
    font-size: 20px;
    background-color: rgb(208, 208, 208);
    color: rgb(60, 60, 60);
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    transition: background-color 0.1s ease-in-out;
}

.search_block_button:hover {
    background-color: rgb(81, 135, 253);
    outline: none;
    color: white;
}

.search_tips {
    margin-top: 15px;
    font-size: 18px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
}

.search_icon {
    translate: 10px 20px;
    position: absolute;
    width: 30px;
}

@media (max-width: 768px) {
    .search_block {
        margin: 0px;
        padding: 30px;
    }
    .search_block_title {
        font-size: 26px;
    }
    .search_tips {
        margin: 0px;
        font-size: 15px;
        font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    }
    .search_block_bar {
        width: 70%;
        height: 40px;
        font-size: 16px;
    }
    .search_block_button {
        margin: 10px auto;
        height: 40px;
        font-size: 18px;
    }
    .search_icon {
        width: 22px;
    }
}

@media (max-width: 576px) {
    .search_block_biology {
        margin: 0px auto;
        width: 370px;
        padding: 20px;
    }
    .search_block_title {
        font-size: 22px;
    }
    .search_block_bar {
        width: 100%;
        font-size: 14px;
        padding-left: 40px;
    }
}