/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Radiology_Examination_Block.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "лучевая диагностика". Radiology_Examination_Block.css
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович
*/
.radiology_examination_block {
    padding-top: 1rem;
    color: rgb(43, 43, 43);
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 27px;
    height: 100%;
}

.radiology_examination_block .radiology_examination_type {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    width: fit-content;
    font-size: 27px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: rgb(25, 106, 237);
}

.radiology_examination_block .radiology_examination_description {
    border: 1px solid rgba(219, 219, 219, 0.494);
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    padding: 15px;
}

.radiology_examination_block .radiology_examination_description .title {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    width: fit-content;
    font-size: 17px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: rgb(25, 106, 237);
}

.radiology_examination_block .radiology_examination_description .text {
    font-size: 17px;
    text-align: justify;
}

.radiology_examination_block .radiology_examination_conclusion {
    border: 1px solid rgba(219, 219, 219, 0.494);
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    padding: 15px;
    margin-top: 1rem;
}

.radiology_examination_block .radiology_examination_conclusion .title {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    width: fit-content;
    font-size: 17px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: rgb(25, 106, 237);
}

.radiology_examination_block .radiology_examination_conclusion .text {
    font-size: 17px;
    text-align: justify;
}

.radiology_examination_block .radiology_examination_treatment {
    border: 1px solid rgba(219, 219, 219, 0.494);
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    padding: 15px;
    margin-top: 1rem;
}

.radiology_examination_block .radiology_examination_treatment .title {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    width: fit-content;
    font-size: 17px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: rgb(25, 106, 237);
}

.radiology_examination_block .radiology_examination_treatment .text {
    font-size: 17px;
    text-align: justify;
}

.radiology_examination_block .radiology_examination_images {
    border: 1px solid rgba(219, 219, 219, 0.494);
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    padding: 15px;
    margin-top: 1rem;
}

.radiology_examination_block .radiology_examination_image_description {
    margin-top: 1rem;
}

.radiology_examination_block .radiology_examination_image_description .title {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    width: fit-content;
    font-size: 17px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: rgb(25, 106, 237);
}

.radiology_examination_block .radiology_examination_image_description .text {
    border: 1px solid rgba(219, 219, 219, 0.494);
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    padding: 15px;
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 17px;
}
