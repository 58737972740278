/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: InProcess.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. InProcess.css
 *	@author: Манжос Геннадий Юрьевич
*/
.in_process_block {
    user-select: none;
    text-align: center;
    font-size: 50px;
    font-family: InterTight-Medium;
    padding: 40px;
    border-radius: 20px;
    background-color: rgb(246, 235, 221);
    color: rgb(255, 123, 0);
    border: 2px solid rgb(248, 139, 51);
    margin: 50px;
    transition: all 0.1s ease-in-out;
}

.in_process_block:hover {
    background-color: rgb(255, 123, 0);
    color: rgb(255, 255, 255);
    border: 2px solid rgb(248, 139, 51);
}

.in_process_block img {
    width: 150px;
    height: 150px;
    animation: rotate 6s linear infinite;
    transition: animation-duration 1s ease-in-out;
}

.in_process_block:hover img {
    animation: rotate 2s linear infinite;
    /* Включение анимации вращения */
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 1400px) {
    .in_process_block {
        font-size: 32px;
        padding: 10px;
    }

    .in_process_block img {
        width: 120px;
        height: 120px;
        padding: 10px;
    }
}

@media (max-width: 1024px) {
    .in_process_block {
        font-size: 28px;
        padding: 0px;
    }

    .in_process_block img {
        width: 100px;
        height: 100px;
        padding: 10px;
    }
}

@media (max-width: 769px) {
    .in_process_block {
        font-size: 20px;
        margin: 10px;
        padding: 0px;
    }
    .in_process_block img {
        width: 80px;
        height: 80px;
        
    }
}

@media (max-width: 456px) {
    .in_process_block {
        font-size: 18px;
        margin: 10px;
        padding: 0px;
    }
    .in_process_block img {
        width: 60px;
        height: 60px;
        
    }
}