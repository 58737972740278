.contacts_page {}

.contacts_grid {
    user-select: none;
    width: 90vw;
    border-radius: 10px;
    border: 1px solid rgb(240, 240, 240);
    margin: 80px auto;
    display: grid;
    grid-template-columns: repeat(auto, 1fr);
    grid-template-rows: auto 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.contacts_sidebar {
    width: 21em;
    height: auto;
    border-right: 1px solid rgb(240, 240, 240);
    grid-area: 2 / 1 / 3 / 2;
    transition: transform 0.3s ease-in-out; 
}

.contacts_sidebar_block {
    padding: 25px;
    line-height: 2.5;
}

.contacts_sidebar.open {
    transform: translateX(0);
}

.contacts_sidebar_title_img {
    display: none;
}

.contacts_sidebar_menu {
    border-radius: 10px;
    padding-left: 10px;
    padding-top: 20px;
    font-size: 1.1em;
    font-family: 'SF-Pro-Display-Medium';
    cursor: pointer;
    animation: all 0.2s ease-in-out;
    line-height: 1.0;
    align-items: center
}

.contacts_sidebar_menu:hover {
    color: rgb(26 59 108); 
    cursor: pointer;
}

.active {
    color: rgb(26 59 108); 
}

.contacts_side_sidebar_content {
    font-size: 20px;
    font-family: 'SF-Pro-Display-Light';
    display: flex;
    align-items: center;
}

.contacts_sidebar_content {
    display: none;   
}

.contacts_side_sidebar_content img {
    width: 28px;
    margin-right: 10px;
}

.close-button {
    display: none;
}

.contacts_main {
    width: 100%;
    height: fit-content;
    grid-area: 2 / 2 / 3 / 3;
}

.contacts_main_block {
    padding: 40px;
}

.contacts_main_title {
    width: 100%;
    padding: 16.4px 40px;
    border-bottom: 1px solid rgb(240, 240, 240);
    font-family: 'SF-Pro-Display-SemiBold';
    grid-area: 1 / 2 / 2 / 3;
    font-size: 50px;
}

.contacts_main_name_title {
    font-family: 'SF-Pro-Display-Regular';
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;  
}
.contacts_main_name_title a {
    text-decoration: none;
    color: unset
}
.contacts_main_name_title a:hover {
    color: #164488
}

.contacts_main_desc {
    margin-bottom: 50px;
    margin-top: 25px;
    font-size: 18px;
    font-family: 'SF-Pro-Display-Light';
    line-height: 1.5;
}

.screen_menu {
    width: 700px;
    height: auto;
    margin: 20px;
    border-radius: 15px;
    left: 0px;
}

.screen_string {
    color: rgb(126, 126, 126);
    font-size: 16px;
    display: flex;
    font-family: 'SF-Pro-Display-Medium';
}

@media (max-width: 1240px) {
    .screen_menu {
        width: 90%;
    }
}

@media (max-width: 1023px) {    
    .contacts_page {
        display: flex;
        flex-direction: column;
    }
    .contacts_grid {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        width: 100vw;
        margin: 10px 0 30px;
        justify-content: center;
    }
    .contacts_main {
        flex-grow: 1;
        width: 80vw;
        height: fit-content;
        margin: 30px auto 0;
    }
    .contacts_main_title {
        width: auto;
        font-size: 38px;
        margin-bottom: 30px;
        text-align: center;
    }
    .contacts_main_block {
        padding: 0px;
    }
    .contacts_sidebar {
        flex-direction: row;
        width: 100%;
        height: 60px;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
    .contacts_sidebar_block {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 25px;
        padding-inline: 0px;
        line-height: 2.5px;
    }
    .contacts_sidebar_menu {
        padding-inline: 30px;
        padding-top: 0px;
        font-size: 1.2em;
        text-align: center;
        width: 200px;
    }
    
    .contacts_sidebar_content {
        width: fit-content;
        max-width: 100%;
        cursor: pointer;
        font-size: 20px;
        font-family: 'SF-Pro-Display-Light';
        display: flex;
        align-items: center;  
        justify-content: flex-start; 
        margin: 10px;          
        margin-top: 2px;
        margin-left: auto;
        padding-inline: 10px;      
        position: fixed;
        z-index: 20;
        top: 141px;
        left: 0px;
        border-radius: 10px;
        background-color: #f0f3f7ed;
        border: 1px solid rgba(214, 214, 214, 0.111);
        transition: box-shadow 0.3s ease;
    }
    .contacts_sidebar_content_img {
        width: 35px;
        padding-inline: 0px;
        margin-right: 8px;
    }
    .hidden {
        display: none;
    }
    .close-button {
        display: flex;
        position: absolute;
        top: 5px;
        right: 5px;
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
    }
    .contacts_sidebar_content:hover {
        cursor: pointer;
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    }
    .screen_menu {
        width: 80%;
    }
}

@media (max-width: 689px) {    
    .contacts_main {
        width: 90vw;
    }  
    .contacts_main_title,
    .contacts_main_name_title,
    .contacts_main_desc,
    .screen_menu,
    .screen_string {
        padding-inline: 30px;
    }
    .contacts_main_title {
        padding: 30px;
    }
    .contacts_main_name_title {
        font-size: 26px;
    }
    .contacts_main_desc {
        margin-bottom: 0px;
        margin-top: 0px;
        font-size: 18px;
    }
    .contacts_sidebar_menu {
        font-size: 18px;
        padding-inline: 16px;
    }
    .screen_menu {
        width: 70%;
    }
}

@media (max-width: 560px) {
    .contacts_sidebar_menu {
        font-size: 16px;
        padding-inline: 10px;
    }
}