/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: NavBar.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. NavBar.css
 *	@author: Манжос Геннадий Юрьевич,     Горбас Александр Петрович
*/
.navbar_block {
    user-select: none;
    z-index: 100;
    position: sticky;
    top: 0;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-wrap: wrap;
    padding-bottom: 16px;
    backdrop-filter: blur(20px) saturate(100%);
    background-color: rgba(219, 219, 219, 0.322);
    border-bottom: 1px solid rgba(214, 214, 214, 0.111);
}

.item1 img {
    margin-left: 25px;
    max-width: 310px;
    align-items: center;
    display: flex;
}

.switch_language_button_navbar {
    display: block;
    color: #535353;
    text-align: left;
    margin-inline: 20px;
    border: 1px solid gainsboro;
    background-color: rgba(220, 220, 220, 0.451);
    font-size: 15px;
    padding: 10px;
    width: 122px;
    border-radius: 30px;
    font-family: 'InterTight-Regular'; /* Include font if used */
    transition: all 0.1s ease;
}

.switch_language_button_navbar:hover {
    display: block;
    color: #535353;
    text-align: left;
    margin-inline: 20px;
    border: 1px solid gainsboro;
    background-color: rgba(220, 220, 220, 0.451);
    font-size: 15px;
    padding: 10px;
    width: 122px;
    border-radius: 30px;
    font-family: InterTight-Regular;
    transition: all 0.1s ease;

    &:hover {
        background-color: rgba(183, 183, 183, 0.714);
        color: rgb(0, 0, 0);
    }
}
.icon_flag {
    margin: auto;
    position: relative;
    margin-right: 5px;
    margin-left: 5px;
    width: 20px;
    top: -2px;
}

.item2 {
    margin: auto;
    margin-right: 25px;
}

.navbar_title {
    border: 1px solid transparent;
    background-color: transparent;
    font-size: 19px;
    text-decoration: none;
    font-family: 'InterTight-Medium', Arial, Helvetica, sans-serif;
    margin: 0 5px;
    padding: 15px;
    color: rgb(0, 0, 0);
    transition: all 0.5s ease;
}

.navbar_title:hover {
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.146);
    color: rgb(184, 25, 25);
    text-decoration: none;
}

.burger-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 30px;
}

.burger-icon {
    cursor: pointer;
    width: 35px;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
}

.burger-icon:hover {
    background-color: #b6b6b64b;
}

.menu-list {
    min-height: 100vh;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(255, 255, 255, 0.18);
    backdrop-filter: blur(20px) saturate(100%);
    z-index: 1000;
}

.menu-list a {
    margin-top: 0;
    padding-left: 15px;
    font-family: 'Inter-Medium', Arial, Helvetica, sans-serif;
    color: #5a5a5a;
    line-height: 60px;
}

.menu-list a:hover {
    transition: all 0.2s ease-in-out;
    color: rgb(184, 25, 25);
    background-color: rgb(243, 243, 243, 1);
}

.navbar_button img,
.navbar_title img {
    width: 28px;
    margin-right: 20px;
}

.navbar_button:hover {
    text-decoration: none;
    color: rgb(37, 115, 217);
}

@media (min-width: 876px) {
    .burger-menu {
        display: none;
    }
}

@media (max-width: 876px) {
    .item2 {
        display: none;
    }
    .navbar_title {
        border: 0;
        border-radius: 0;
        padding: 0;
    }
    .navbar_title:hover {
        border: 0;
    }
    .burger-menu {
        display: flex;
        position: relative;
        flex: 2;
    }    
    .menu-list {
        top: 0;
        right: 0;
        width: auto;
        display: none;
        transition: transform 0.5s ease;
        transform: translateX(100%);
    }
    .menu-list.opened {
        display: flex;
        height: 100vh;
        width: 270px;
        transform: translateX(0);
    }
    .close-btn {
        padding: 3px;
        border-radius: 50%;
        margin-top: 20px;
        margin-bottom: 15px;
        margin-left: 15px;
        display: flex;
        flex-direction: row;
        height: 32px;
        width: 32px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }
    .close-btn:hover {
        background-color: #b6b6b64b;
    }
    .menu_sidebar_title{
        display: none;
    }
}

@media (max-width: 591px) {
    .navbar_block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;
        padding: 0px;
    }
    .item1 {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .item1 img {
        top: 10px;
        margin: 4px;
    }
    .switch_language_button_navbar {
        align-items: center;
        margin-bottom: 10px;
    }
    .button-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .switch_language_button_navbar {
        max-width: 120px;
        text-align: center;
        margin-inline: 10px;
    }
    .burger-menu {
        flex-direction: column;
        align-items: flex-end;
        max-width: 100px;
        padding: 0px;
        flex: 2 1;
        padding-inline: 20px;
    }
    .menu-list {
        top: 0;
        right: 0;
        width: auto;
        display: none;
        transition: transform 0.5s ease;
        transform: translateX(100%);
    }
    .menu-list.opened {
        display: flex;
        height: 100vh;
        width: 270px;
        transform: translateX(0);
    }
}