.news_page-title {
    user-select: none;
    color: rgb(69, 69, 69);
    text-align: center;
    font-size: 75px;
    font-family: 'InterTight-SemiBold', Arial, Helvetica, sans-serif;
    padding-top: 30px;
    padding-bottom: 30px;
}

.news_page-content {
    padding-left: 2rem;
    padding-right: 2rem;
    font-family: 'InterTight', Arial, Helvetica, sans-serif;
}

.news_page-content h1 {
    user-select: none;
    color: rgb(69, 69, 69);
    font-size: 35px;
}