/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: KLD_Data_Entrie_Page.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "клд". В этом файле находится содержимое страницы, которая выводится при переходе на страницу кейса конкретной категории.
 *	@author: Манжос Геннадий Юрьевич, Белов Михаил Александрович
*/
.dzi_section {
    margin-top: 1rem;
    min-height: 15vh;
}

.case_flex_onerow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.case_flex_onerow .case_bold_name {
    padding: 0;
    margin: 0;
}

.case_flex_onerow .age {
    color: rgb(63, 139, 238);
    font-family: 'InterTight-SemiBold', Arial, Helvetica, sans-serif;
    font-weight: 100;
    padding: 0.1rem 0;
    font-size: 1.4rem;
    margin: 0;
}

.case_flex_onerow .gender {
    color: rgb(63, 139, 238);
    font-family: 'InterTight-SemiBold', Arial, Helvetica, sans-serif;
    font-weight: 100;
    padding: 0.1rem 0;
    font-size: 1.4rem;
    margin-right: 0.5rem;
}

.gender_age_bar_kld {
    border: 1px solid rgba(131, 159, 215, 0.072);
    margin-top: 15px;
    margin-bottom: 30px;
    width: 200px;
    padding: 25px;
    border-radius: 20px;
    font-family: 'InterTight-Regular', Arial, Helvetica, sans-serif;
    font-size: 22px;
    background-color: rgba(192, 204, 227, 0.238);
}

.age {
    font-family: 'InterTight-SemiBold', Arial, Helvetica, sans-serif;
    color: rgb(72, 102, 141);
    font-size: 35px;
}