/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: fonts.css
 *  @description: Этот файл является частью клиентской стороны проекта. Подключение шрифтов, используемых в проекте.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович
*/
@font-face {
    font-family: InterTight-Bold;
    src: url('fonts/InterTight-Bold.ttf')
}

@font-face {
    font-family: InterTight-SemiBold;
    src: url('fonts/InterTight-SemiBold.ttf')
}

@font-face {
    font-family: InterTight-Medium;
    src: url('fonts/InterTight-Medium.ttf')
}

@font-face {
    font-family: InterTight-Regular;
    src: url('fonts/InterTight-Regular.ttf')
}

@font-face {
    font-family: cuyabra-Regular;
    src: url('fonts/cuyabra-Regular.ttf')
}

@font-face {
    font-family: Inter-SemiBold;
    src: url('fonts/Inter-SemiBold.ttf')
}


@font-face {
    font-family: Inter-Regular;
    src: url('fonts/Inter-Regular.ttf')
}

@font-face {
    font-family: SF-Pro-Display-SemiBold;
    src: url('fonts/SF-Pro-Display-Semibold.otf')
}

@font-face {
    font-family: SF-Pro-Display-Medium;
    src: url('fonts/SF-Pro-Display-Medium.otf')
}

@font-face {
    font-family: SF-Pro-Display-Regular;
    src: url('fonts/SF-Pro-Display-Regular.otf')
}

@font-face {
    font-family: SF-Pro-Display-Light;
    src: url('fonts/SF-Pro-Display-Light.otf')
}