/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: BottomCaseNavigation.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. BottomCaseNavigation.css
 *	@author: Манжос Геннадий Юрьевич, Белов Михаил Александрович
*/
/*
 *  Блок с кнопками, которые расположены на отдельной "строке" с отступами между друг другом (space-between).
*/
.button_block {
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    justify-content: space-between;
}

/*
 *  Кнопка из блока выше.
*/
.button_block .case_button {
    flex: auto;
    margin: 1px;
    text-align: center;
    max-width: 120px;
    cursor: pointer;
    border-radius: 50px;
    background-color: rgb(255, 255, 255);
    color: rgb(55, 118, 254);
    padding: 15px;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    font-size: 21px;
    transition: background-color 0.2s ease;
    text-decoration: none;
}

.button_block .case_button.disabled {
    cursor: default;
    color: #bababa;
}



/*
 *  Кнопка из блока выше (широкая).
*/
.button_block .case_button.wide {
    max-width: 250px;
    background-color: #ffffff;
}

.case_button:hover:not(.disabled) {
    background-color: rgb(255, 255, 255);
}

.case_button.wide:hover {
    background-color: #e1e1e1;
}

.case_button.next:hover {
    background-color: #e1e1e1;
}

.case_button.previous:hover {
    background-color: #e1e1e1;
}




@media only screen and (max-width: 600px) {
    .button_block .case_button {
        max-width: 100px;
        padding: 12px;
    }

    .button_block .case_button.wide {
        max-width: fit-content;
        padding: 10px;
    }
}