/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: ECG_Categories_Page.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "экг". В этом файле находится содержимое страницы, которая выводится при переходе на страницу категории.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович
*/

.ekg_rightpage_pic_description {
    font-size: 22px;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    margin-bottom: 50px;
}

@keyframes example {
    from {
        top: -30px;
    }
    to {
        top: 0px;
    }
}

.ekg_rightpage_info_22 {
    margin-bottom: 50px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 17px;
}

.ekg_rightpage_info_title {
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 20px;
    margin-bottom: 20px;
}

.ekg_main_pic1 img {
    border-radius: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 500px;
    display: block;
}

@media (max-width: 600px) {


    .ekg_rightpage_pic_description {
        font-size: 18px;
        font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
        margin-bottom: 50px;
    }
    .ekg_main_pic1 img {
        border-radius: 20px;
        width: 100%;
        padding: 5px;
    }
    .ekg_main_container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
    }
}