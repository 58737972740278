/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: ECG_Data_EntriesList.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "экг". В этом файле находится компонент, отвечающий за вывод списка кейсов конкретной категории.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович
*/
.ecg_list_flex {
    display: grid;
    gap: 1px;
    flex-wrap: nowrap;
    justify-content: stretch;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
}

/* Для экранов шириной до 479px (мобильные устройства) */
@media(max-width: 479px) {
    .ecg_list_flex {
        display: flex;
        flex-direction: column;
        width: 200px;
    }
}