/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Support_Page.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Support Support_Page.css
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович,
*/
.support_page {
    color: rgb(50, 50, 50);
    background-color: rgba(229, 234, 243, 1);
    display: grid;
    justify-content: center;
    height: 1600px;
}

.support_grid {
    line-height: 1.2;
    /* height: 100vh; */
    height: 68vh;
    padding: 20px;
    width: 1000px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 0.2fr repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.support_title {
    position: relative;
    margin-top: 100px;
    margin-bottom: 100px;
    width: 520px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 40px;
    padding: 20px;
    grid-area: 1 / 1 / 2 / 3;
    animation: customAni 1s ease 0s normal both;
}

    @keyframes customAni {
        0% {
            transform: translateY(-20px);
        }

        100% {
            transform: translateY(5px);
        }
    }

.support_title_icon {
    padding: 20px;
    grid-area: 1 / 3 / 2 / 4;
}


.support_title_text {
    padding: 20px;
    font-size: 30px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    grid-area: 2 / 1 / 3 / 4;
    animation: customAni 2s ease 0s normal both;
}

@keyframes customAni {
    0% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(5px);
    }
}

.support_account,
.support_medical_data,
.support_faq,
.support_volunteers,
.support_demo_request {
    height: 300px;
}

.support_account {
    padding: 20px;
    width: 100%;
    height: 300px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 24px;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    grid-area: 3 / 1 / 4 / 2;
}

.support_medical_data {
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 24px;
    border-radius: 20px;
    padding: 20px;
    background-color: rgb(255, 255, 255);
    grid-area: 3 / 2 / 4 / 3;
}

.support_faq {
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 24px;
    border-radius: 20px;
    padding: 20px;
    background-color: rgb(255, 255, 255);
    grid-area: 3 / 3 / 4 / 4;
}

.support_message {
    height: 250px;
    overflow: hidden;
    z-index: 1;
    position: relative;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 24px;
    border-radius: 20px;
    padding: 20px;
    background-color: rgb(255, 255, 255);
    grid-area: 4 / 1 / 5 / 4;
}

.support_volunteers {
    overflow: hidden;
    z-index: 1;
    position: relative;
    height: 300px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 24px;
    border-radius: 20px;
    padding: 20px;
    background-color: rgb(255, 255, 255);
    grid-area: 5 / 1 / 6 / 3;
}

.support_volunteers_text {
    color: rgb(101, 101, 101);
    width: 435px;
    padding-top: 20px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 18px;
}

.support_message_text {
    color: rgb(101, 101, 101);
    width: 350px;
    padding-top: 20px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 18px;
}

.support_demo_request {
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 24px;
    border-radius: 20px;
    padding: 20px 50px 0px 20px;
    background-color: rgb(255, 255, 255);
    grid-area: 5 / 3 / 6 / 4;
}

.support_link {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 20px;
    text-decoration: none;
}

.support_button {
    color: rgb(255, 255, 255);
    background-color: rgb(57, 146, 255);
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    border-style: none;
    text-align: center;
    height: 60px;
    width: 170px;
    border-radius: 30px;
    font-size: 20px;
    margin-top: 40px;
    transition: all 0.2s ease;
}

.support_button:hover {
    background-color: rgb(42, 101, 173);
}

.support_icon img {
    width: 43%;
    margin-bottom: 5px
}

.support_data_icon img {
    width: 30px;
}

.support_emoji {
    position: absolute;
    top: 5px;
    right: 0px;
    z-index: 1;
    width: 28%;
}

.support_emoji_doctor {
    position: absolute;
    top: 50px;
    right: -35px;
    z-index: 1;
    width: 42%;
}

.support_settings_icon {
    position: absolute;
    width: 50%;
    top: 50px;
    right: -330px;
}

@media(max-width:1024px) {
    .support_page {
        justify-items: center;
        height: 1200px;
    }
    .support_grid {
        justify-content: center;
        width: 800px;
        grid-column-gap: 4px;
        grid-row-gap: 4px;
    }
    .support_title {
        width: 50vw;
        font-size: 30px;
        margin-top: 2vh;
        margin-bottom: 4vh;
    }
    .support_settings_icon {
        right: -200px;
        width: 25%;
    }
    .support_title_text {
        width: 65vw;
        padding: 20px;
        font-size: 30px;
    }
    .support_icon img {
        width: 36%;
        margin-bottom: 2px;
    }
    .support_emoji {
        top: 90px;
        width: 24%;
    }
    .support_emoji_doctor {
        right: -13px;
        width: 30%;
        top: 105px;
    }
    .support_account,
    .support_medical_data,
    .support_faq,
    .support_message,
    .support_volunteers,
    .support_demo_request {
        font-size: 19px;
        height:250px;
    }
    .support_message_text {
        width: 600px;
    }    
}

@media(max-width: 820px) {
    .support_page {
        height: 1150px;
    }
    .support_grid {
        width: 700px;
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        grid-template-rows: repeat(3, auto);
        padding-bottom: 100px; 
    }
    .support_title {
        font-size: 30px;
        width: 60%;
        grid-area: 1 / 1 / 2 / 3;
    }
    .support_title_text {
        font-size: 25px;
    }
    .support_title_icon {
        display: block; 
        grid-area: 2 / 1 / 3 / 2; 
    }
    .support_title_text {
        grid-area: 2 / 1 / 3 / 3;
    }
    .support_account {
        grid-area: 3 / 1 / 4 / 2;
    }
    .support_medical_data {
        grid-area: 3 / 2 / 4 / 3;
    }
    .support_faq {
        grid-area: 4 / 1 / 5 / 2; 
    }
    .support_message {
        grid-area: 4 / 2 / 5 / 3; 
    }
    .support_volunteers {
        grid-area: 5 / 1 / 6 / 2; 
    }
    .support_demo_request {
        grid-area: 5 / 2 / 6 / 3;
    }   
    .support_account,
    .support_medical_data,
    .support_faq,
    .support_message,
    .support_volunteers,
    .support_demo_request {
        font-size: 16px;
        height: 230px;
    }
    .support_link {
        font-size: 16px;
    }
    .support_message_text {
        width: 200px;
        padding-top: 20px;
        font-size: 16px;
    }
    .support_emoji {
        width: 46%;
        top: 100px;
    }
    .support_button {        
        height: 45px;
        width: 110px;
        font-size: 17px;
        margin-top: 20px;
    }
    .support_emoji_doctor {
        top: 100px;
        width: 43%;
    }
    .support_volunteers_text {
        width: 250px;
        font-size: 16px;
    }
}

@media(max-width: 700px) {
    .support_page {
        justify-items: center;
        height: 1050px;
    }
    .support_grid {
        width: 550px;
        gap:1vw;
    }
    .support_title {
        font-size: 25px;
        width: 70%;
    }
    .support_settings_icon {
        right: -141px;
        width: 25%;
    }
    .support_title_text {
        width: 65vw;
        padding: 20px;
        font-size: 22px;
    }
    .support_account,
    .support_medical_data,
    .support_faq,
    .support_message,
    .support_volunteers,
    .support_demo_request {
        font-size: 16px;
        width: 45vw;
        height: 215px;
    }
    .support_icon img {
        width: 32%;
    }
    .support_emoji {
        width: 40%;
        top: 120px;
    }
    .support_emoji_doctor {
        top: 110px;
        right: -2px;
    }
    .support_link {
        font-size: 16px;
    }
    .support_volunteers .support_link {
        top: 160px;
        position: absolute;
    }
    .support_message_text,
    .support_volunteers_text{
        font-size: 14px;
        width: 100%;
    }
    .support_button {
        height: 37px;
        width: 95px;
        font-size: 16px;
    }
}

@media(max-width:560px) {
    .support_page {        
        height: fit-content;
    }
    .support_grid {
        height: fit-content;
        width: fit-content;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .support_title {
        font-size: 22px;
        width: 90%;
        padding-left: 14px;
        margin: 0px;
    }
    .support_settings_icon {
        display: none;
    }
    .support_title_text {
        font-size: 20px;
        width: 90%;
    }
    .support_account,
    .support_medical_data,
    .support_faq,
    .support_message,
    .support_volunteers,
    .support_demo_request {
        width: 80vw;
        font-size: 18px;
        height: 200px;
    }
    .support_emoji {
        width: 34%;
        top: 83px;
    }
    .support_emoji_doctor {
        top: 95px;
        width: 27%;
    }
    .support_message_text,
    .support_volunteers_text{
        width: auto;
    }
    .support_icon img {
        width: 20%;
    }
}

@media(max-width:500px) {
    .support_emoji_doctor {
        top: 107px;
    }
}
@media(max-width:454px) {
    .support_emoji {
        top: 108px;
    }
    .support_emoji_doctor {
        width: 32%;
    }
}