/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Pages.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Pages.css
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
.pagination_block {
    margin-top: 20px;
    width: fit-content;
    margin-bottom: 50px;
}

.container-pagination {
    display: flex;
}

.pagination_active {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 22px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #42a7ff;
    color: rgb(248, 255, 255);
    border: none;
    text-align: center;
    line-height: 50px;
    margin-left: 10px;
    cursor: default;
    text-decoration: none;
}

.pagination_noactive {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 22px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: rgb(62, 62, 62);
    border: none;
    text-align: center;
    line-height: 50px;
    margin-left: 10px;
    transition: all 0.1s ease-in-out;
    text-decoration: none;
}

.pagination_active:hover {
    text-decoration: none;
}

.pagination_noactive:hover {
    background-color: rgb(224, 235, 255);
    text-decoration: none;
}