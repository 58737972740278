/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: ECG_Data_EntriesTable_Page.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "экг". В этом файле находится содержимое страницы, которая выводится при переходе на страницу со списком кейсов, относящихся к конкретной категории.
 *	@author: Манжос Геннадий Юрьевич
*/
.content_cases {
    margin: 20px;
    display: grid;
    grid-template-columns: 410px 0.7fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 1px;
    margin-bottom: 300px;
}

.content_filters {
    grid-area: 1 / 1 / 2 / 2;
}

.content_body {

    grid-area: 1 / 2 / 2 / 3;
}

.content_breadcrumbs {
    margin: 20px;
}

.content_Pages {
    margin-top: 50px;
}

/* Для экранов шириной до 479px (мобильные устройства) */
@media(max-width: 479px) {
    .content_cases {
        display: flex;
        flex-direction: column;
    }
}