/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Patanatomy_Categories_Page.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "патанатомия". В этом файле находится содержимое страницы, которая выводится при переходе на страницу категории.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович
*/
.rightpage_info {
    margin-bottom: 50px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 20px;
}