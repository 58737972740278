/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: About_Application_Page.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. About_Application_Page.css
 *	@author: Манжос Геннадий Юрьевич, Белов Михаил Александрович, Горбас Александр Петрович, Gennadii
*/
.about_app_page {
    overflow: hidden;
    /* Отключает скроллинг, если содержимое превышает размер экрана */
    user-select: none;
}

.logo_pic_about {
    width: 80%;
    display: flex;
    margin: 0px auto;
}

.about_text_block_text_2 {
    justify-content: center;
    width: 1200px;
    margin: 85px auto;
    display: flex;
    padding-bottom: 200px;
    color: #4598ff;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 1.5vw;
}

@keyframes scroll-left {
    0% {
        transform: translateX(100%);
    }

    50% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

@keyframes scroll-right {
    0% {
        transform: translateX(-100%);
    }

    50% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.line-1 {
    animation: scroll-left 25s linear infinite;
}

.line-2 {
    animation: scroll-right 30s linear infinite;
}

.line-3 {
    animation: scroll-left 35s linear infinite;
}

.line-4 {
    animation: scroll-right 40s linear infinite;
}

.line-5 {
    animation: scroll-left 45s linear infinite;
}

.about_categories_block {
    margin-top: 50px;
    background: -webkit-linear-gradient(#f5f1ff, #f65656);
}

.about_cat_block_grid_2 {
    margin: 50px;
    display: grid;
    row-gap: 5px;
}

.about_categories_images_text_sec {
    border-radius: 100px;
    border: 1px solid rgb(116, 148, 245);
    background-color: #bb94df00;
    padding: 6vh;
    color: #4598ff;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    font-size: 5vh;
}

.about_categories_images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    height: fit-content;
    background-color: #f3f8ff;
    padding: 50px;
}

.about_categories_images_text {
    line-height: 60px;
    width: 850px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 5vh;
    padding-top: 100px;
    padding-left: 100px;
}

.about_categories_images_text_mini {
    line-height: 50px;
    width: 850px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 3.5vh;
    padding-top: 100px;
    padding-left: 100px;
}

.about_simple_button {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    border: none;
    margin-top: 50px;
    background-color: #a81515;
    color: #ffffff;
    font-size: 23px;
    width: 190px;
    height: 70px;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;

    &.about_simple_button:hover {
        background-color: #000000;
    }
}

.about_simple_text {
    padding-top: 50px;
    font-size: 20px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
}

.about_categories_block_text_2 {
    user-select: none;
    text-decoration: 3px rgb(255, 255, 255) underline;
    line-height: 70px;
    background: linear-gradient(45deg, #ffffff, #f0f0f0, #ffffff);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: #ffffff; /* transparent; */
    /* color: #000000; */
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    font-size: 55px;
    padding: 50px;
    padding-bottom: 150px;
}

.about_contacts_block_text {
    border-top: 1px solid rgb(224, 224, 224);
    border-bottom: 1px solid rgb(224, 224, 224);
    padding-top: 50px;
    padding-left: 50px;
    height: 450px;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    font-size: 40px;
    color: #3d3d3d;
    background-color: #efefef;
}

.about_categories_block_text {
    padding-top: 100px;
    text-align: left;
    width: 600px;
    line-height: 50px;
    margin-left: 150px;
    display: grid;
    padding-bottom: 100px;
    color: #2f2f2f;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 55px;
}

.about_grid {
    color: #2d2d2d;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 0fr);
    grid-column-gap: 0px;
}

/* .about_first_img,
.about_second_img {
    position: absolute;
    z-index: 2;
    width: 500px;
    top: -290px;
    margin: 0 100px;
}

.about_first_img {
    left: 0;
}

.about_second_img {
    right: 0;
} */

.bar {
    padding-top: 100px;
    display: grid;
    grid-template-rows: repeat(2, 0.5fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
    z-index: 0;
    backdrop-filter: blur(5px) saturate(200%);
    -webkit-backdrop-filter: blur(8px) saturate(200%);
    background-color: rgb(238, 238, 238);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00c9e3", endColorstr="#010452", GradientType=1);
    color: rgb(232, 232, 232);
    align-items: center;
    justify-content: center;

}

.bar_title_mini {
    display: flex;
    margin: 0px auto;
    color: rgb(101, 101, 101);
    line-height: 1;
    width: 350px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 30px;
    animation: customAni 1s ease 0s normal both;
    grid-area: 2 / 1 / 3 / 3;

    @keyframes customAni {
        0% {
            transform: translateY(-20px);
        }

        100% {
            transform: translateY(5px);
        }
    }

}

.about_first_block {
    background-color: #ffffff;
    width: 100%;
    position: relative;
    padding: 50px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 20px;
    grid-area: 2 / 1 / 3 / 2;
}

.splice_scene {
    transform: scale(0.7);
    z-index: 1;
    top: 550px;
    right: 2vw;
    left: -10vw;
    position: absolute;
}

.about_main_description {
    left: 100px;
    margin: auto;
    width: 800px;
    font-size: 25px;
}

.about_flex_anim_block {
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 150px;
    user-select: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    padding: 5px;
    width: auto;
    height: 400px;
    text-align: center;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 1.1vw;
}

.div1 {
    border: 1px solid rgba(157, 157, 157, 0.09);
    border-radius: 30px;
    padding: 15px;
    grid-area: 1 / 1 / 2 / 2;
    background-color: aliceblue;
}

.div2 {
    background-color: aliceblue;
    border: 1px solid rgba(157, 157, 157, 0.09);
    border-radius: 30px;
    padding: 15px;
    grid-area: 1 / 2 / 2 / 3;
}

.div3 {
    background-color: aliceblue;
    border: 1px solid rgba(157, 157, 157, 0.09);
    border-radius: 30px;
    padding: 15px;
    grid-area: 1 / 3 / 2 / 4;
}

.div4 {
    background-color: aliceblue;
    border: 1px solid rgba(157, 157, 157, 0.09);
    border-radius: 30px;
    padding: 15px;
    grid-area: 1 / 4 / 2 / 5;
}

.div5 {
    background-color: aliceblue;
    border: 1px solid rgba(157, 157, 157, 0.09);
    border-radius: 30px;
    padding: 15px;
    grid-area: 2 / 1 / 3 / 2;
}

.div6 {
    background-color: aliceblue;
    border: 1px solid rgba(157, 157, 157, 0.09);
    border-radius: 30px;
    padding: 15px;
    grid-area: 2 / 2 / 3 / 3;
}

.div7 {
    background-color: aliceblue;
    border: 1px solid rgba(157, 157, 157, 0.09);
    border-radius: 30px;
    padding: 15px;
    grid-area: 2 / 3 / 3 / 4;
}

.div8 {
    background-color: aliceblue;
    border: 1px solid rgba(157, 157, 157, 0.09);
    border-radius: 30px;
    padding: 15px;
    grid-area: 2 / 4 / 3 / 5;
}

.div9 {
    background-color: aliceblue;
    border: 1px solid rgba(157, 157, 157, 0.09);
    border-radius: 30px;
    padding: 15px;
    grid-area: 3 / 1 / 4 / 2;
}

.div10 {
    background-color: aliceblue;
    border: 1px solid rgba(157, 157, 157, 0.09);
    border-radius: 30px;
    padding: 15px;
    grid-area: 3 / 2 / 4 / 3;
}

.div11 {
    background-color: aliceblue;
    border: 1px solid rgba(157, 157, 157, 0.09);
    border-radius: 30px;
    padding: 15px;
    grid-area: 3 / 3 / 4 / 4;
}

.div12 {
    padding-top: 120px;
    background-color: aliceblue;
    border: 1px solid rgba(157, 157, 157, 0.09);
    border-radius: 30px;
    grid-area: 3 / 4 / 4 / 5;
}

.div1_numbers {
    font-size: 4vw;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
}

.about_second_block {
    margin: auto;
    width: 800px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    grid-area: 3 / 1 / 4 / 2;
    backdrop-filter: blur(8px) saturate(200%);
    -webkit-backdrop-filter: blur(8px) saturate(200%);
    background-color: rgba(255, 255, 255, 0.32);
    border: 1px solid rgba(209, 213, 219, 0.3);
    padding: 30px;
    border-radius: 30px;
}

.about_second_block_text {
    font-size: 20px;
    margin-bottom: 20px;
}

.about_text_block_text {
    line-height: 1;
    width: 1200px;
    padding: 200px;
    display: flex;
    margin: 0px auto;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 50px;
}

.about_third_block {
    justify-content: center;
    padding-top: 100px;
    margin: 0px auto;
    width: 700px;
    grid-area: 4 / 1 / 5 / 2;
}

.about_third_block_text {
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 70px;
    animation: customAni 4s ease 0s normal both;

    @keyframes customAni {
        0% {
            transform: translateY(-20px);
        }
        100% {
            transform: translateY(5px);
        }
    }
}

.about_third_block_text_2 {
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 24px;
}

.about_biology_block {
    padding: 50px;
    margin: auto;
    width: 1200px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 20px;
    grid-area: 5 / 1 / 6 / 2;
}

.about_credits {
    margin: auto;
    width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 16px;
    grid-area: 7 / 1 / 8 / 2;
}

.about_biology_grid {
    border-radius: 50px;
    padding: 30px;
    backdrop-filter: blur(8px) saturate(200%);
    -webkit-backdrop-filter: blur(8px) saturate(200%);
    background-color: rgb(242, 242, 242);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 2px;
    grid-row-gap: 2px;
}

.about_biology_grid_text {
    padding: 20px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 22px;
    width: 480px;
    grid-area: 1 / 1 / 2 / 2;
}

.about_biology_grid_img2 {
    border-radius: 50px;
    width: 100%;
    grid-area: 1 / 2 / 2 / 3;
}

.about_biology_grid_img3 {
    border-radius: 50px;
    width: 100%;
    grid-area: 2 / 1 / 3 / 2;
}

.about_biology_grid_img4 {
    border-radius: 50px;
    width: 100%;
    grid-area: 2 / 2 / 3 / 3;
}

.about_bento_3_block1 {
    grid-area: 6 / 1 / 7 / 2;
}

.about_bento_3_grid {
    font-size: 20px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    background-color: rgb(214, 225, 234);
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.about_bento_3_grid_1 {
    grid-area: 1 / 1 / 2 / 2;
    padding: 25px;
    backdrop-filter: blur(8px) saturate(200%);
    -webkit-backdrop-filter: blur(8px) saturate(200%);
    background-color: rgba(183, 183, 183, 0.32);
    border: 1px solid rgba(209, 213, 219, 0.3);
    border-radius: 30px;
}

.about_bento_3_grid_2 {
    grid-area: 1 / 2 / 2 / 3;
    padding: 25px;
    border-radius: 30px;
    backdrop-filter: blur(8px) saturate(200%);
    -webkit-backdrop-filter: blur(8px) saturate(200%);
    background-color: rgba(183, 183, 183, 0.32);
    border: 1px solid rgba(209, 213, 2 19, 0.3);
}

.about_bento_3_grid_3 {
    width: auto;
    grid-area: 2 / 1 / 3 / 3;
    padding: 25px;
    border-radius: 30px;
    backdrop-filter: blur(8px) saturate(200%);
    -webkit-backdrop-filter: blur(8px) saturate(200%);
    background-color: rgba(183, 183, 183, 0.32);
    border: 1px solid rgba(209, 213, 219, 0.3);
}

.about_bento_3_grid_img1 {
    display: flex;
    margin: auto;
    position: relative;
    padding-bottom: 20px;
    width: 200px;
}

.about_bento_3_grid_img2 {
    display: flex;
    margin: auto;
    padding-bottom: 20px;
    width: 200px;
}

.about_bento_3_grid_img3 {
    width: 100%;
    padding-bottom: 20px;
}

@media (min-height: 1600px) or (min-width: 2048px) {
    .about_categories_images {
        padding: 100px;
        height: 60rem;
        background-color: #f3f8ff;
    }
    .about_categories_images_text,
    .about_categories_images_text_mini {
        width: 50vw;
    }
    .about_categories_images_text_sec {
        font-size: 3rem;
        height: 300px;
    }
    .about_categories_images_text {
        line-height: 90px;
        font-size: 3rem;
    }
    .about_categories_images_text_mini {
        line-height: 80px;
        font-size: 2.5rem;
    }
}

@media (max-width: 1600px) {
    .about_text_block_text,
    .about_text_block_text_2 {
        font-size: 3rem;
        padding-bottom: 100px;
        align-items: center;
    }
    .about_text_block_text {
        width: 90%;  
    }
    .about_text_block_text_2 {
        font-size: 2rem; 
        width: 80%;  
    }
    .about_categories_images {
        height: fit-content;
        padding-bottom: 5vw;
    }
    .about_categories_images_text_sec {
        font-size: 2.8rem;
        border-radius: 70px;
    }
    .about_cat_block_grid_2 {
        align-items: baseline;
        margin-top: 120px;
    }
}

@media (max-width: 1400px) {
    .bar {
        padding-top: 50px;
    }
    .about_text_block_text_2 {
        margin: 0px auto;  
    }
    .about_categories_block_text_2 {
        font-size: 3.5rem;
        line-height: 4rem;
        text-decoration: 2px rgb(255, 255, 255) underline;
    }
    .about_categories_images {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: fit-content;
        background-color: #f3f8ff;
        padding: 50px;
    }
    .about_categories_images_text {
        line-height: 3.5rem;
        font-size: 3rem;
        width: fit-content;        
        padding: 50px;
    }
    .about_categories_images_text_mini {
        line-height: 2.5rem;
        font-size: 2rem;
        width: fit-content;
        padding: 50px;
    }
    .about_cat_block_grid_2 {
        margin-top: 0px;
    }
    .about_contacts_block_text {
        padding: 60px;
        height: auto;
        font-size: 2rem;
    }
}

@media (max-width: 1200px) {
    .bar {
        padding-top: 4vh;
        padding-bottom: 2vw;    
    }
    .about_first_block {
        position: unset;
        padding: 60px;
        font-size: 20px;
    }
    .about_biology_block {
        padding: 30px;
        margin: auto;
        width: 100vw;    
        height: auto;
        font-size: 20px;
    }   
    .about_biology_grid {
        padding: 10px; 
        gap: 4px; 
    }
    .about_biology_grid_text {
        padding: 20px;
        font-size: 1.2rem;
        padding-right: 0px;
        width: 98%;
    }
    .about_text_block_text {
        font-size: 2.5rem;
        padding: 100px;
    }
    .about_text_block_text_2 {
        font-size: 1.5rem;;
    }
    .about_categories_block_text {
        padding-top: 3vh;
        padding-bottom: 3vh;
    }
    .about_categories_block_text_2 {
        font-size: 3rem;
        line-height: 3.5rem;
        padding: 0px;
        padding-bottom: 8vh;
        text-decoration: 1px rgb(255, 255, 255) underline;
    }
    .about_categories_images_text,
    .about_categories_images_text_mini {
        padding-left: 65px;
    }
    .about_categories_images_text {
        line-height: 3.3rem;
        font-size: 2.3rem;
    }
    .about_categories_images_text_mini {
        line-height: 2rem;
        font-size: 1.5rem;
    }
    .about_categories_images_text_sec {
        font-size: 2.5rem;
        padding: 4vh;
        border-radius: 50px;
    }
}

@media (max-width: 920px) {
    .bar_title_mini {
        font-size: 1.7rem;
        padding-bottom: 40px;
        width: 100vw;
        justify-content: space-around;
    }
    .about_first_block {
        width: 100vw;
        padding: 30px;
    }
    .about_main_description {
        margin: auto;
        width: 90%;
        font-size: 1.2rem;
    }
    .about_third_block {
        padding-top: 30px;
        padding-left: 30px;
        margin: 40px;
    }
    .about_third_block_text {
        font-size: 2.8em;
        width: 90%;
    }
    .about_biology_grid_text {
        padding: 20px;
        font-size: 1.15rem;;
    }
    .about_text_block_text {
        font-size: 2rem;
    }
    .about_categories_block_text {
        padding-bottom: 3rem;
    }
    .about_categories_block_text_2 {        
        font-size: 2.5rem;
        line-height: 3rem;
        padding: 0px;
        padding-bottom: 3rem;
    }
    .about_categories_images_text {    
        line-height: 3.3rem;    
        font-size: 2.3rem;
    }
    /* .about_categories_images_text,
    .about_categories_images_text_mini {
        padding-bottom: 20px 20px;

    } */
    .about_categories_images_text_mini {
        line-height: 2.5rem;    
        font-size: 1.5rem;
    }
    .about_categories_images_text_sec {
        font-size: 2.2rem;
    }
}

@media (max-width: 768px) {
    .logo_pic_about {
        width: 85%;
    }
    .bar_title_mini {
        font-size: 1.3rem;
    }
    .about_third_block {
        padding-top: 1em;
        margin-bottom: 0px;
    }
    .about_biology_block {
        padding: 30px;
    }    
    .about_biology_grid {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, auto);
        gap: 10px;
        padding: 30px;
    }
    .about_text_block_text {
        font-size: 1.7rem;
        line-height: 2.2rem;
        width: 95vw;
    }
    .about_text_block_text_2 {
        font-size: 1.4rem;
        margin-inline: 80px;
    }
    .about_text_block_text,
    .about_text_block_text_2 {
        padding-bottom: 70px;
    }
    .about_categories_block_text {
        width: 100vw;
        text-align: center;
        margin-left: 0px; 
        font-size: 3rem;
    }
    .about_categories_block_text_2 {
        font-size: 2rem;
        line-height: 2.5rem;
    }
    .about_categories_images {
        display: flex;
        flex-direction: column;
    }
    .about_cat_block_grid_1 {
        margin-top: 50px;
    }
    .about_cat_block_grid_2 {
        align-items: baseline;
        margin-top: 50px;
    }
    .about_categories_images_text,
    .about_categories_images_text_mini {
        padding: 0px 0px 40px 0px;
    }
    .about_contacts_block_text {
        font-size: 1.8rem;
    }
}

@media (max-width: 576px) {
    .logo_pic_about {
        width: 90%;
        padding-bottom: 10px;
    }
    .bar_title_mini {
        font-size: 1.2rem;
        padding-bottom: 30px;
    }
    .about_third_block {
        padding: 0.5em;
    }
    .about_third_block_text {
        font-size: 1.9rem;
    }
    .about_third_block_text_2 {
        font-size: 1.3em;
    }
    .about_categories_block_text {
        font-size: 2rem;
    }
    .about_biology_block,
    .about_biology_grid, 
    .about_biology_grid_text{
        padding: 12px;
    }   
    .about_biology_grid {
        gap: 4px;  
    }
    .about_biology_grid_text {
        font-size: 0.9em;
        width: 100%;
    }
    .about_text_block_text {
        width: 100vw;
        padding-bottom: 50px;
        padding-inline: 50px;
    }
    .about_text_block_text_2 {
        margin: 40px;
        padding-inline: 0px;        
        padding-bottom: 50px;
        width: 90vw;
    }
    .about_categories_block_text_2 {
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .about_categories_images_text_mini {
        line-height: 2.4rem;
        font-size: 1.4rem;
    }
    .about_categories_images_text,
    .about_categories_images_text_sec {
        font-size: 2rem;
        line-height: 3rem;
    }
    .about_categories_images_text_sec {
        padding: 3vh;
    }
    .about_cat_block_grid_2 {
        margin: 4vh;
        margin-top: 2vh;
    }
    .about_cat_block_grid_2 {
        justify-content: center;
        margin-top: 0vh;
    }
    .about_contacts_block_text {
        padding: 40px;
        font-size: 1.5rem;
    }
    .about_simple_button {
        margin-top: 30px;
        font-size: 1.3rem;
        width: 130px;
        height: 60px;
        border-radius: 50px;
    }
}

@media (max-width: 430px) {
    .bar_title_mini {
        font-size: 1em;
    }
    .about_third_block {
        margin: 10px;
        padding-left: 20px;
    }    
    .about_categories_images_text,
    .about_text_block_text {
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .about_text_block_text_2 {
        margin: 30px;
        font-size: 1.2rem;
    }
    .about_categories_images {
        padding: 20px 20px 20px 20px;
    }
    .about_categories_images_text,
    .about_categories_images_text_mini {
        padding-left: 0vh;
    }
    .about_categories_images_text_sec {
        font-size: 1.5rem;
        padding: 2vh;
        text-align: center;
    }
    .about_categories_images_text_mini {
        line-height: 1.8rem;
    }
    .about_contacts_block_text {
        font-size: 1.4rem;
    }
    .about_simple_button {
        margin-top: 20px;
        width: 120px;
        height: 50px;
    }
}