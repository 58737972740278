/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: CountdownTimer.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Countdown CountdownTimer.css
 *	@author: Белов Михаил Александрович
*/
.timer {
    display: flex;
    flex-direction: row;
}