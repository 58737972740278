/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Support_guide.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Support Support_guide.css
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович, Манжос Геннадий Юрьевич
*/
.support_guide_page {
    user-select: none;
    min-height: 100vh;
}

.support_guide_page_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.support_guide_header {
    height: 300px;
    color: rgb(255, 255, 255);
    background-color: rgb(81, 106, 228);
    padding: 30px;
    grid-area: 1 / 1 / 2 / 4;
}

.grey_logo_pimu {
    width: 100px;
}

.support_guide_header_icon_and_title {
    display: flex;
    flex-direction: row;
}

.support_guide_header_contact_head {
    padding-left: 20px;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.21);
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
}

.support_guide_header_contact {
    color: #fbfbfb;
    padding: 10px;
}

.support_guide_header_contact:hover {
    cursor: pointer;
    text-decoration: 2px rgb(255, 255, 255) underline;
}

.support_guide_header_title {
    margin-left: 20px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 65px;
}

.support_guide_header_title2 {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    margin-top: 20px;
    width: 500px;
    font-size: 17px;
}

.support_guide_main_title_and_icon {
    display: flex;
    flex-direction: row;
}

.support_guide_sidebar {
    padding-top: 10px;
    width: 350px;
    min-height: 100vh;
    background-color: rgb(241, 244, 251);
    font-family: Inter-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 21px;
    grid-area: 2 / 1 / 3 / 2;
    line-height: 40px;
}

.support_sidebar_icon {
    margin-top: -7px;
    width: 30px;
    align-items: center;
    vertical-align: middle;
    margin-right: 10px;
}

.support_main_icon {
    margin-right: 20px;
    width: 43px;
    height: 43px;
}


.support_guide_sidebar_menu {
    margin: 2px;
    text-align: start;
    width: 330px;
    border: none;
    background-color: rgba(163, 97, 17, 0);
    margin-left: 10px;
    border-radius: 20px;
    color: rgb(55, 55, 55);
    padding: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.support_guide_sidebar_menu:hover {
    color: rgb(0, 110, 255);
    background-color: rgba(255, 255, 255, 0.567);
}


.support_guide_sidebar_menu:focus {
    color: rgb(0, 110, 255);
    background-color: rgb(255, 255, 255);
}

.support_guide_sidebar_menu.active {
    color: rgb(0, 110, 255);
    background-color: rgb(255, 255, 255);
}

.support_guide_main_title {
    line-height: 50px;
    font-family: Inter-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 50px;
}

.support_guide_text_title {
    padding-top: 50px;
    padding-bottom: 30px;
    font-family: Inter-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 30px;
}

.support_guide_main {
    height: auto;
    width: 850px;
    font-family: Inter-Regular, Arial, Helvetica, sans-serif;
    font-size: 20px;
    padding: 50px;
    grid-area: 2 / 2 / 3 / 4;
}

.support_guide_main_selected.hidden {
    display: none;
}

@media (max-width: 1200px) {
    .support_guide_page,
    .support_guide_page_grid,
    .support_guide_header,
    .support_guide_main {
        width: auto;  
    }
    .support_guide_header {
        height: fit-content;
        padding-bottom: 40px;
    }
    .support_guide_main_selected{
        width: 55vw;
    }
    .support_guide_main_title {
        font-size: 40px;
    }
    .support_guide_sidebar {
        width: 34vw;
        line-height: 30px;
        font-size: 19px;
    }
    .support_guide_sidebar_menu {
        width: 32vw;  
    }
}

@media (max-width: 940px) {
    .support_guide_header {
        height: 250px;
    }
    .support_guide_header_title {
        font-size: 50px;
    }
    .grey_logo_pimu {
        width: 80px;
        height: auto;
    }
    .support_guide_header_title2 {
        margin-top: 20px;
        width: 400px;
        font-size: 17px;
    }
    .support_guide_main_selected{
        width: 57vw;
    }
    .support_guide_main {
        font-size: 18px;
        padding: 40px;
    }
    .support_guide_main_title {
        font-size: 35px;
    }
    .support_guide_text_title {
        padding-bottom: 20px;
        font-size: 27px;
        line-height: 33px;
    }
    .support_guide_sidebar {
        width: 35vw;
        line-height: 30px;
        font-size: 17px;
    }
    .support_guide_sidebar_menu {
        width: 33vw;
        padding: 17px;
    }
}

@media (max-width: 800px) {
    .support_guide_page_grid {
        display: flex;
        flex-direction: column;
    }
    .support_guide_header {
        height: 230px;
    }
    .support_guide_header_title2 {
        margin-top: 20px;
        width: 70vw;
        font-size: 17px;
    }
    .grey_logo_pimu {
        width: 70px;
        height: 70px;
    }
    .support_guide_sidebar {
        width: auto;
        min-height: 40px;
        height: fit-content;
        padding: 10px;
        line-height: 20px;
        font-size: 18px;
    }
    .support_guide_sidebar_menu {
        width: 45vw;
        padding: 17px;
    }
    .support_sidebar_icon {
        margin-top: 0px;
        width: 25px;
        margin-right: 6px;
    }
    .support_guide_sidebar {
        text-align: center;
    }
    .support_guide_main_title {
        font-size: 36px;
    }
    .support_guide_main_selected {
        width: auto;
        padding:20px;
    }
}

@media (max-width: 589px) {
    .support_guide_header {
        height: auto;
    }
    .support_guide_header_title {
        font-size: 37px;
    }
    .support_guide_header_title2 {
        font-size: 17px;
        width: auto;
    }
    .grey_logo_pimu {
        width: 60px;
        height: 60px;
    }
    .support_guide_sidebar {
        font-size: 17px;
        margin: 0px;
    }
    .support_guide_sidebar_menu {
        width: 45vw;
        padding: 10px;
    }
    .support_sidebar_icon {
        margin-right: 2px;
    }
    .support_guide_main {
        font-size: 18px;
        padding: 20px;
        padding-bottom: 50px;
    }
    .support_guide_main_title {
        font-size: 30px;
    }
}

@media (max-width: 520px) {
    .support_guide_header_title {
        font-size: 35px;
        line-height: 40px;
    }
    .support_guide_header_title2 {
        font-size: 17px;
        width: auto;
    }
    .grey_logo_pimu {
        width: 50px;
        height: 50px;
    }
    .support_guide_sidebar {
        text-align: center;
        margin: 0px;
    }
    .support_guide_sidebar_menu {
        text-align: center;
        width: 70vw;
    }
    .support_guide_main_title {
        font-size: 27px;
        line-height: 33px;
    }
}