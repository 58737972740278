/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: GenderBar.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. GenderBar.css
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
.filters_block {
    padding: 20px;
    background-color: rgb(246, 246, 246);
    width: auto;
    height: 320px;
    border-radius: 25px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 0fr);
    grid-column-gap: 0px;
    grid-row-gap: 20px;
}

.gender_list_title_filter {
    font-size: 17px;
    color: rgb(80, 80, 80);
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
}

.gender_list {
    grid-area: 1 / 1 / 2 / 2;
}

.age_bar {
    grid-area: 2 / 1 / 3 / 2;
}

.filters_block .gender_button {
    margin-right: 3px;
    user-select: none;
    display: inline-block;
    font-size: 17px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    justify-content: center;
    text-align: center;
    padding: 10px;
    width: 120px;
    color: rgb(80, 80, 80);
    cursor: pointer;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.067);
    text-decoration: none;
    background-color: rgb(255, 255, 255);
    transition: all 0.2s ease-in-out;
}


.gender_list_title {
    color: rgb(80, 80, 80);
    margin-bottom: 5px;
    font-size: 15px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
}

.gender_button:hover {
    text-decoration: none;
    background-color: rgba(232, 232, 232, 0.507)
}

.gender_button.active {
    background-color: rgb(233, 239, 255);
    color: rgb(0, 0, 0);
}

@media (max-width: 479px) {
    .gender_list {
        padding: 0px;
        width: 330px;
        padding: 10px;
    }

    .gender_list .gender_button {
        font-size: 13px;
        padding: 10px;
        width: 100px;
    }

    .container {
        padding: 0px;
    }

}