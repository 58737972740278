/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: TopCaseNavigation.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. TopCaseNavigation.css
 *	@author: Манжос Геннадий Юрьевич
*/
/*
 *  Блок с кнопками, которые расположены на отдельной "строке" с отступами между друг другом (space-between).
*/
.top_button_block {
    margin-left: auto;
    margin-right: 0;
    margin-top: 15px;
    margin-bottom: 15px;
}

/*
 *  Кнопка из блока выше.
*/

.top_button_block .top_case_button {
    flex: auto;
    margin: 1px;
    text-align: center;
    max-width: 120px;
    cursor: pointer;
    border-radius: 50px;
    background-color: rgb(255, 255, 255);
    color: rgb(55, 118, 254);
    padding: 15px;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    font-size: 21px;
    transition: background-color 0.2s ease;
    text-decoration: none;
}

.top_button_block .top_case_button.disabled {
    cursor: default;
    color: #909090;
}

/*
 *  Кнопка из блока выше (широкая).
*/
.top_button_block .top_case_button.wide {
    max-width: 250px;
    background-color: #ffffff;

}

.top_case_button:hover:not(.disabled) {
    background-color: rgb(255, 255, 255);
}



.top_case_button.wide:hover {
    background-color: #e3e3e3;
}

.top_case_button.next:hover {
    background-color: #e3e3e3;
}

.top_case_button.previous:hover {
    background-color: #e3e3e3;
}

@media only screen and (max-width: 600px) {
    .top_button_block .top_case_button {
        padding: 12px;
        font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
        font-size: 17px;
        transition: background-color 0.2s ease;
        text-decoration: none;
    }

}