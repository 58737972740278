/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Demo_Version_Page.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Demo_Version_Page.css
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович
*/
.demo_page {
    background-size: cover;
    /* background-image: url('/public/35010676_8257878.jpg'); */
    height: 120vh;
}

.demo_page_grid {
    height: 120vh;
    background: hsla(241, 100%, 49%, 1);

    background: radial-gradient(circle, hsla(241, 100%, 49%, 1) 0%, hsla(0, 0%, 100%, 1) 100%, hsla(241, 52%, 32%, 1) 100%);

    background: -moz-radial-gradient(circle, rgb(48, 45, 214) 0%, hsla(0, 0%, 100%, 1) 100%, hsla(241, 52%, 32%, 1) 100%);

    background: -webkit-radial-gradient(circle, rgb(163, 173, 207) 0%, hsla(0, 0%, 100%, 1) 100%, hsla(241, 52%, 32%, 1) 100%);

    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#0400FA", endColorstr="#FFFFFF", GradientType=1);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    margin: 0px auto;
    padding-top: 10vh;
    padding-left: 10vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.demo_page_description {
    height: 450px;
    max-width: 686px;
    line-height: 0.9;
    text-align: left;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 5em;
    padding: 50px;
    background: -webkit-linear-gradient(#272727, rgb(0, 89, 255));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: demo_page_description 1s cubic-bezier(.55, .085, .68, .53) both;
    animation: demo_page_description 1s cubic-bezier(.55, .085, .68, .53) both
}

.demo_link {
    font-size: 20px;
    color: rgb(0, 0, 0);
    font-family: "InterTight-Regular", Arial, Helvetica, sans-serif;
    grid-area: 1 / 1 / 2 / 2;
}

.demo_page_button_buy {
    width: 220px;
    margin-left: 15px;
    margin-top: 25px;
    padding: 20px;
    color: rgb(255, 255, 255);
    border: 2px solid rgb(255, 255, 255);
    border-radius: 50px;
    background-color: rgba(198, 198, 198, 0);
    font-size: 24px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
    -webkit-animation: start_page-title 1s cubic-bezier(.55, .085, .68, .53) both;
    animation: start_page-title 1s cubic-bezier(.55, .085, .68, .53) both
}

.demo_page_button_buy:hover {
    color: rgb(110, 110, 110);
    background-color: rgb(249, 249, 249);
}

.demo_page_animated_text{
    padding-right: 5vw;
    width: 100%;
}

.animated_text {
    display: grid;
    flex-direction: column;
    /* width: 100%; */
    max-width: 680px; 
    height: auto;
    min-height: 220px;
    color: #3f3f3f;
    font-size: 1.5em;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
}

.demo_page_email {
    width: 100%;
    max-width: 600px;
    color: #425b7c;
    font-size: 18px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    margin-top: 100px;
    /* margin-left: 50px; */
}

.ekg3d_icon {
    -webkit-animation: demo_page_description 1s cubic-bezier(.55, .085, .68, .53) both;
    animation: demo_page_description 1s cubic-bezier(.55, .085, .68, .53) both;
    display: flex;
    width: 100%; 
    height: auto; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: -10px; 
}

.spline_scene_demo {
    -webkit-animation: demo_page_description 1s cubic-bezier(.55, .085, .68, .53) both;
    animation: demo_page_description 1s cubic-bezier(.55, .085, .68, .53) both;
    width: 100%;
    margin-left: auto;
    margin-top: -320px; 
}

@keyframes demo_page_description {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0
    }

    100% {
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1
    }
}

@media (max-width: 1400px) {
    .ekg3d_icon {
        margin-left: -170px;
        margin-top: 7px;
    }
}

@media (max-width: 1200px) {
    .demo_page_grid {
        padding-top: 6vh;
        padding-left: 6vw;
    }
    .ekg3d_icon {
        margin-top: -610px;
        margin-left: 0px;
    }
    .demo_page_description {
        width: 90%;
        font-size: 4em;
    }
    .spline_scene_demo {
        order: 4;
        position: absolute;
        width: 20%;
        height: 20%;
        max-width: 60%;
        box-sizing: border-box;
        margin-top: 0px;
        top: 300px;
        left: -200px;
        z-index: 1;
    }
    .demo_page_animated_text {
        height: auto;        
        padding: 20px;
        padding-right: 25px;
        order: 3;
        position: relative;
        z-index: 10;
    }
    .demo_page_email {
        margin-top: 70px;
    }
    .demo_page_button_buy {
        margin-left: 0px;
        margin-top: 50px;
    }
}

@media (max-width: 920px) {
    .demo_page_grid {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0px;
    }
    .demo_link {
        text-align: center;
    }
    .demo_page_description {
        font-size: 3em;
        height: 415px;
        text-align: center;
        width: 90vw;
    }
    .animated_text {
        max-width: unset; 
    }
    .demo_page_animated_text {
        width: 95vw;
        text-align: center;
        padding: 30px;
        margin-bottom: 20px;
        margin-right: 0px;
        margin-top: 100px;
    }
    .demo_page_button_buy {
        margin-left: 410px;
        margin-top: -30px;
    }
    .demo_page_email {
        width: 50%;
        margin-left: 400px;
        padding-right: 10px;
        text-align: left;
    }
    .ekg3d_icon {
        margin-top: -530px;
        margin-left: -95px;
    }
}

@media (max-width: 820px) {
    .demo_page_email {
        margin-left: 340px;
    }
}

@media (max-width: 720px) {
    .demo_link {
        text-align: center;
    }
    .demo_page_description {
        font-size: 2em;
    }
    .animated_text {
        font-size: 1.2em;
        z-index: 5; 
    }
    .demo_page_animated_text {
        padding: 30px;
        margin-bottom: 20px;
        margin-right: 0px;
        margin-top: 50px;
    }
    .demo_page_button_buy {   
        z-index: 8;     
        backdrop-filter: blur(15px) saturate(200%);
        -webkit-backdrop-filter: blur(15px) saturate(200%);
        background-color: rgba(255, 254, 254, 0.345);
        border: 1px solid rgba(209, 213, 219, 0.3);
        margin-bottom: 60px;
        margin-left: 50vw;
        margin-top: 10px;
    }
    .ekg3d_icon {
        margin-top: -73vh;
        margin-left: 0vw;
    }
    .spline_scene_demo {
        opacity: 0.1;
    }
    .demo_page_email {
        width: 100%;
        margin-top: 300px;
        margin-left: 0px;
        text-align: center;
    }
}

@media (max-width: 630px) {    
    .ekg3d_icon {
        margin-top: -70vh;
        margin-left: -25vw;
    }
    .demo_page_animated_text {
        margin-top: 90px;
    }
    .demo_page_button_buy {   
        margin-left: 45vw;
        margin-top: -35px;
    }
}

@media (max-width: 500px) {
    .demo_page {
        height: 100vh;
    }
    .demo_page_button_buy {   
        margin-left: 0px;
        margin-top: 10px;
    }
    .spline_scene_demo {
        display: none;
    }
    .demo_page_email {
        margin-top: 20px;
    }
}