/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Dzi_Data_EntriesItem.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "патанатомия". В этом файле находится компонент, отвечающий за вывод одного элемента в списке кейсов конкретной категории.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
.container {
    margin-left: 5vw;
    margin-right: 5vw;
    min-height: 100vh;
    margin-bottom: 150px;
}

.case_preview_title1 {
    color: rgb(247, 247, 247);
    font-size: 1.2vw;
    font-family: 'InterTight-SemiBold', Arial, Helvetica, sans-serif;
}

.demo_version_tif_preview {

    background: rgb(28, 107, 255);
    position: absolute;
    margin: 15px;
    padding: 14px;
    border-radius: 12px;
    color: #ffffff;
    z-index: 1;
    font-size: 20px;
    font-family: 'InterTight-SemiBold', Arial, Helvetica, sans-serif;

}

.case_preview-item1 {
    cursor: pointer;
    border-radius: 20px;
    width: 22vw;
    height: 320px;
    min-width: 10vw;
    overflow: hidden;
    position: relative;
}

.case_preview-item1 img {
    width: 170%;
    height: 150%;
    object-fit: cover;
    position: relative;
    transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1);


}

.case_preview-item1 img:hover {
    transform: scale(1.3);
}


.case_preview_info1 {
    line-height: 28px;
    border: 1px solid rgba(255, 255, 255, 0.094);
    backdrop-filter: blur(3px) saturate(150%);
    -webkit-backdrop-filter: blur(15px) saturate(170%);
    background-color: rgba(63, 88, 135, 0.181);
    border-top-right-radius: 30px;
    margin-right: 20px;

    position: absolute;
    padding: 30px;
    bottom: 0px;
}

.case_preview_latin_name1 {
    margin-top: 5px;
    margin-bottom: 5px;
    color: rgb(62, 106, 219);
    font-size: 0.7vw;
    font-family: 'Inter-Regular', Arial, Helvetica, sans-serif;
}



@media only screen and (max-width: 600px) {
    .case_preview-item1 {
        margin-right: 45px;
        width: fit-content;
        height: 380px;
    }

    .case_preview-item1 img {
        width: 380px;
        object-fit: cover;
        position: relative;

    }

    .case_preview_info1 {
        font-size: 14px;
    }

    .case_preview_latin_name1 {
        font-size: 16px;
        font-family: 'InterTight-Regular', Arial, Helvetica, sans-serif;
    }


    .case_preview_title1 {
        color: rgb(255, 255, 255);
        font-size: 20px;
        font-family: 'InterTight-SemiBold', Arial, Helvetica, sans-serif;
    }


}