/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Subscribe_Page.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Subscribe_Page.css
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович,
*/
.subscribe_grid {
    margin: 50px auto;
    border-radius: 15px;
    width: 100vw;
    background-color: rgb(247, 247, 247);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-bottom: 50px;
}

.subscribe_title {
    padding: 25px;
    padding-left: 70px;
    border-bottom: 1px solid rgb(224, 224, 224);
    font-family: 'SF-Pro-Display-SemiBold';
    font-size: 55px;
    grid-area: 1 / 1 / 2 / 4;
}

.subscribe_desc {
    margin-top: 20px;
    padding: 25px;
    padding-left: 70px;
    font-size: 20px;
    font-family: 'SF-Pro-Display-Light';
    grid-area: 2 / 1 / 3 / 4;
}

.subscribe_category_university {
    padding: 25px;
    padding-left: 70px;
    font-size: 35px;
    font-family: 'SF-Pro-Display-Medium';
    grid-area: 3 / 1 / 4 / 4;
}

.subscribe_category_hr_university {
    margin-left: 50px;
    margin-right: 50px;
    border: 2px solid rgb(0, 38, 255);
    grid-area: 4 / 1 / 5 / 4;
    border-radius: 5px;
}

.subscribe_category_info_batch_university {
    margin: 0px auto;
    padding: 25px;
    padding-left: 70px;
    font-size: 17px;
    color: #999999;
    font-family: 'SF-Pro-Display-Light';
    grid-area: 5 / 1 / 6 / 4;
}

.subscribe_batchs_university {
    grid-area: 6 / 1 / 7 / 4;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 320px);
    grid-template-rows: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 10px;
    margin-bottom: 100px;
}

@media (max-width: 1024px) {
    .subscribe_batchs_university,
    .subscribe_batchs_college {
        grid-template-columns: repeat(2, 320px);
    }
}

.subscribe_category_college {
    padding: 25px;
    padding-left: 70px;
    font-size: 35px;
    font-family: 'SF-Pro-Display-Medium';
    grid-area: 7 / 1 / 8 / 4;
}

.subscribe_category_hr_college {
    margin-left: 50px;
    margin-right: 50px;
    border: 2px solid rgb(0, 255, 157);
    grid-area: 8 / 1 / 9 / 4;
}

.subscribe_category_info_batch_college {
    margin: 0px auto;
    padding: 25px;
    padding-left: 70px;
    font-size: 17px;
    color: #999999;
    font-family: 'SF-Pro-Display-Light';
    grid-area: 9 / 1 / 10 / 4;
}

.subscribe_batchs_college {
    margin-bottom: 100px;
    grid-area: 10 / 1 / 11 / 4;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 320px);
    grid-template-rows: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 10px;
}

@media (max-width: 1024px) {
    .subscribe_batchs_university,
    .subscribe_batchs_college {
        grid-template-columns: repeat(2, 320px);
    }
    .subscribe_grid {
        margin-bottom: 30px;        
        margin-top: 30px;
    }
}

@media (max-width: 720px) {
    .subscribe_batchs_university,
    .subscribe_batchs_college {
        grid-template-columns: repeat(1, 320px);
    }
}

.batch {
    border: 1px solid rgb(227, 227, 227);
    border-radius: 10px;
    padding: 25px;
    background: rgb(235, 235, 235);
    background: -moz-linear-gradient(0deg, rgba(235, 235, 235, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(235, 235, 235, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(0deg, rgba(235, 235, 235, 1) 0%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=1);
}

.batch_name {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    font-size: 30px;
    color: #3d3d3d;
    font-family: 'SF-Pro-Display-Medium';
}

.batch_name img {
    margin-right: 5px;
}

.batch_rub_symbol {
    margin-right: 10px;
    font-size: 35px;
    font-family: 'SF-Pro-Display-Light';
}

.batch_prices {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
}

.batch_price {
    font-size: 35px;
    font-family: 'SF-Pro-Display-Medium';
}

.batch_price_year {
    margin-left: 5px;
    margin-top: 5px;
    font-size: 27px;
    font-family: 'SF-Pro-Display-Light';
}

.batch_exp img {
    margin-right: 5px;
}

.batch_exp {
    font-size: 18px;
    font-family: 'SF-Pro-Display-Light';
}

.buy_batch_a {
    text-decoration: none;
}

.buy_batch_a:hover {
    text-decoration: none;
}

.batch_button_buy {
    border-radius: 20px;
    color: whitesmoke;
    background-color: black;
    font-size: 24px;
    font-family: 'SF-Pro-Display-Medium';
    border: none;
    justify-content: center;
    width: 180px;
    padding: 18px;
    display: flex;
    margin: 0px auto;
    margin-top: 50px;
    margin-bottom: 50px;
    transition: all 0.1s ease-in-out;
}

.batch_button_buy:hover {
    background-color: rgb(0, 85, 255);
}