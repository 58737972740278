/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: DemoVersionBanner.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. DemoVersionBanner.css
 *	@author: Белов Михаил Александрович
*/
.demo_version
{
    background-color: #ffd8d8;
    border: 1px solid #ffc1c1;
    border-radius: 5px;
    color: rgb(69, 69, 69);
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    margin: 2% auto 0 auto;
    max-width: 1200px;
    padding: 0;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16"><path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/></svg>');
    background-repeat: no-repeat;
    background-color: #ffd8d8;
    background-size: 200px;
    background-position: left;
    background-blend-mode: overlay;
    padding: 10px 0 10px 20px;
}

.demo_version.category_page
{
    border: 0;
    margin: 0;
    max-width: 100%;
    padding: 5px;
    border-radius: 0;
}

.demo_version .text-large
{
    font-size: 2.5rem;
    padding: 0;
    margin: 0;
}

.demo_version.category_page .text-large
{
    font-size: 1.8rem;
}

.demo_version .text-small
{
    font-size: 1.2rem;
    padding: 0;
    margin: 0;
}

.demo_version.category_page .text-small
{
    font-size: 1rem;
}